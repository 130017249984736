/* eslint-disable react/jsx-key */
import { Dialog } from '@headlessui/react';
import { DocumentIcon } from '@heroicons/react/24/outline';
import { PlayIcon, PlusIcon, TrashIcon, PaperAirplaneIcon } from '@heroicons/react/24/solid';
import { Editor } from '@tinymce/tinymce-react';
import moment from 'moment';
import 'moment/locale/nl-be';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import { toast } from 'react-toastify';
import { FiAlertCircle } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
  CEGO_ROLES,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_SELECTED_TAB,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  classNames,
  resolveMoney,
} from '../utils';
import { toastError, toastSuccess } from '../utils/toast';
import { IAttendee } from '../utils/attendee';
import { DeleteForm } from './deleteForm';
import Button from './tailwind/button';
import { Combobox, ComboboxItem } from './tailwind/comboBox';
import { Input } from './tailwind/input';
import { FileUpload } from './tailwind/landingZone';
import { MultiFileUpload } from './tailwind/multiLandingZone';
import { Modal } from './tailwind/modal';
import { RolesComponent } from './tailwind/roles';
import { SmallModal } from './tailwind/smallmodal';
import { StringList } from './tailwind/stringList';
import { Switch } from './tailwind/switch';
import { Table } from './tailwind/table';
import { Textarea } from './tailwind/textarea';
import { QuoteList } from './tailwind/quoteList';
import CheckboxComponent from './tailwind/checkboxComponent';
import { ExtraDateList } from './tailwind/extraDates';

interface Props {
  title: string;
  caption?: string;
  schema: DetailSchema;
}

export interface DetailSchema {
  id: string;
  object: any; // complete object
  permission: string;
  sections: DetailSection[];
  handleCreate?: () => void;
  handleUpdate?: () => void;
  handleDelete?: () => void;
  hideCreateCallback?: (value?: string) => boolean;
  hideUpdateCallback?: (value?: string) => boolean;
  hideDeleteCallback?: (value?: string) => boolean;
  extraButtons?: any[];
  selectedTab: string;
  setSelectedTab: Dispatch<SetStateAction<string>>;
  delete?: {
    title: string;
    text: string;
    buttonText?: string;
  };
}

export interface DetailSection {
  id: string;
  title: string;
  caption?: string;
  width: number;
  fields: DetailField[];
  hideCallback?: (value?: string) => boolean;
}

export interface DetailField {
  id: string;
  name: string;
  label: string;
  value?: any;
  required?: boolean;
  type: string;
  max?: number;
  min?: number;
  step?: number;
  placeholder?: string;
  caption?: string;
  error_caption?: string;
  width: number;
  combobox?: {
    items: ComboboxItem[];
    query?: string;
    setQuery?: any;
  };
  selectbox?: {
    items: string;
  };
  linkbutton?: {
    link: string;
    text: string;
  };
  table?: {
    onCreate?: (item: any) => void;
    columns: any[];
    items: any[];
    onSelect?: (item: any) => void;
    selection?: any;
    showCreateCallback?: (value?: string) => boolean;
    lazyLoading?: boolean;
    loadMore?: boolean;
    loadMoreCallback?: () => void;
    loading?: boolean;
  };
  fasttable?: {
    columns: any[];
    items: any[];
  };
  detail?: {
    open: boolean;
    setOpen: any;
    title: string;
    caption?: string;
    schema: DetailSchema;
  };
  dropzone?: {
    selectedFile: File | null;
    setSelectedFile: Dispatch<SetStateAction<File | null>>;
  };
  multidropzone?: {
    selectedFiles: File[] | null;
    setSelectedFiles: Dispatch<SetStateAction<File[] | null>>;
  };
  custom?: {
    setObject: any;
  };
  custombutton?: {
    text: string;
    icon: any;
  };
  onClick?: any;
  callback: (name: string, value: any) => any;
  validateCallback?: (value: string) => {
    valid: boolean;
    message?: string;
  };
  disableCallback?: (value?: string) => boolean;
  hideCallback?: (value?: string) => boolean;
}

const validateSchema = (schema: DetailSchema) => {
  let valid = true;

  schema.sections.forEach(section => {
    section.fields.forEach(field => {
      if (field.validateCallback && !field.validateCallback(field.value).valid) {
        valid = false;
      }
    });
  });

  return valid;
};

export const DetailForm: React.FC<Props> = ({ title, caption, schema }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  useEffect(() => {
    schema.setSelectedTab(DEFAULT_SELECTED_TAB);
  }, []);

  return (
    <div className=' m-8'>
      <Dialog.Title className='mb-4'>
        <h1 className='text-2xl font-bold leading-6 text-cego-black mb-4'>{title}</h1>
        {caption && <p className='mb-4'>{caption}</p>}
      </Dialog.Title>

      <div>
        <div className='sm:hidden'>
          <label htmlFor='tabs' className='sr-only'>
            Select a tab
          </label>
          <select
            id='tabs'
            name='tabs'
            className='block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
            value={schema.selectedTab} // Bind to state
            onChange={e => schema.setSelectedTab(e.target.value)}
          >
            {schema.sections.map(section => (
              <option key={section.title}>{section.title}</option>
            ))}
          </select>
        </div>
        <div className='hidden sm:block '>
          <nav className='flex space-x-4' aria-label='Tabs'>
            {schema.sections.map(section => (
              <>
                {((section.hideCallback && section.hideCallback()) || !section.hideCallback) && (
                  <>
                    <a
                      key={section.title}
                      className={classNames(
                        section.title === schema.selectedTab
                          ? 'bg-cego-semigray text-gray-800'
                          : 'bg-cego-offwhite text-gray-600 hover:text-gray-800',
                        'rounded-md px-3 py-2 text-base font-medium cursor-pointer',
                      )}
                      onClick={() => {
                        schema.setSelectedTab(section.title);
                      }}
                    >
                      {section.title}
                    </a>
                  </>
                )}
              </>
            ))}
          </nav>
        </div>
      </div>
      <div className=''>
        <form>
          <div className='relative my-4 flex-1'>
            {schema.sections.map((item, index) => (
              <>
                {((item.hideCallback && item.hideCallback()) || !item.hideCallback) && (
                  <>
                    <Section key={item.id} section={item} isSubmit={isSubmit} schema={schema} />
                  </>
                )}
              </>
            ))}
          </div>
          {schema.object && schema.object.id ? (
            <>
              <div
                className={classNames(
                  'flex space-x-2',
                  (((!schema.hideUpdateCallback || schema.hideUpdateCallback() === false) && schema.handleUpdate) ||
                    ((!schema.hideCreateCallback || schema.hideCreateCallback() === false) && schema.handleCreate) ||
                    ((!schema.hideDeleteCallback || schema.hideDeleteCallback() === false) && schema.handleDelete) ||
                    (schema.extraButtons && schema.extraButtons.length < 0)) &&
                    'p-3 bg-cego-lightgray rounded-md',
                )}
              >
                {schema.handleUpdate && (
                  <>
                    {schema.handleUpdate && (!schema.hideUpdateCallback || schema.hideUpdateCallback() === false) && (
                      <Button
                        primary
                        onClick={() => {
                          setIsSubmit(true);

                          if (validateSchema(schema) && schema.handleUpdate) {
                            schema.handleUpdate();
                          }
                        }}
                      >
                        {'Opslaan'}{' '}
                        <span className='inline ml-1'>
                          <PlayIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
                        </span>
                      </Button>
                    )}
                    {schema.handleDelete && (!schema.hideDeleteCallback || schema.hideDeleteCallback() === false) && (
                      <Button
                        secondary
                        onClick={() => {
                          if (schema.handleDelete) {
                            setIsOpenDelete(true);
                          }
                        }}
                      >
                        {schema.delete && schema.delete.buttonText ? schema.delete.buttonText : 'Verwijderen'}{' '}
                        <span className='inline ml-1'>
                          <TrashIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
                        </span>
                      </Button>
                    )}
                    {schema.extraButtons &&
                      schema.extraButtons.map((button: any, index: number) => <div key={button.key}>{button.render()}</div>)}
                  </>
                )}
              </div>
            </>
          ) : (
            <div
              className={classNames(
                'flex space-x-2',
                (!schema.hideCreateCallback || schema.hideCreateCallback() === false) &&
                  schema.handleCreate &&
                  'p-3 bg-cego-lightgray rounded-md',
              )}
            >
              {schema.handleCreate && (!schema.hideCreateCallback || schema.hideCreateCallback() === false) && (
                <Button
                  primary
                  onClick={() => {
                    setIsSubmit(true);

                    if (validateSchema(schema) && schema.handleCreate) {
                      schema.handleCreate();
                    }
                  }}
                >
                  {'Opslaan'}{' '}
                  <span className='inline ml-1'>
                    <PlayIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
                  </span>
                </Button>
              )}
            </div>
          )}
        </form>
        {!schema.handleUpdate &&
          schema.extraButtons &&
          schema.extraButtons.map((button: any, index: number) => <div key={button.key}>{button.render()}</div>)}

        {schema.handleDelete && (
          <SmallModal open={isOpenDelete} setOpen={setIsOpenDelete}>
            <>
              <DeleteForm
                title={schema.delete?.title ? schema.delete.title : 'Verwijderen ?'}
                text={
                  schema.delete?.text
                    ? schema.delete.text
                    : 'Weet je zeker dat je dit wilt verwijderen? dit kan niet ongedaan worden gemaakt.'
                }
                handleDelete={schema.handleDelete}
                setIsOpenDelete={setIsOpenDelete}
              />
            </>
          </SmallModal>
        )}
      </div>
    </div>
  );
};

export const Section: React.FC<{
  section: DetailSection;
  isSubmit: boolean;
  schema: DetailSchema;
}> = ({ section, isSubmit, schema }) => {
  return (
    <div className={classNames(section.title === schema.selectedTab ? 'visible' : 'hidden', '')}>
      <div className='flex flex-wrap justify-between'>
        {section.fields.map(item => (
          <Field key={item.id} field={item} isSubmit={isSubmit} schema={schema} />
        ))}
      </div>
    </div>
  );
};

export const Field: React.FC<{
  field: DetailField;
  isSubmit: boolean;
  schema: DetailSchema;
}> = ({ field, isSubmit, schema }) => {
  const [isTimeOut, setIsTimeOut] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  // Introduce a hook to detect mobile viewports
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTimeOut(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < /* Your breakpoint for mobile */ 768);
    };
    handleResize(); // Check on initial render
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {((field.hideCallback && field.hideCallback()) || !field.hideCallback) && (
        <div className='py-2.5' style={{ width: isMobile ? '100%' : `${field.width}%` }}>
          <label
            htmlFor={field.id}
            className={classNames(
              isSubmit && field.validateCallback && !field.validateCallback(field.value).valid ? 'text-cego-red' : 'text-cego-darkgray',
              'block ml-0.5 mb-0.5 text-sm font-medium leading-6',
            )}
          >
            {field.label && field.label.length > 0 ? field.label : ''}
            {field.required && <span>*</span>}{' '}
            {isSubmit && field.validateCallback && !field.validateCallback(field.value).valid && isTimeOut && (
              <span>{`(${field.validateCallback(field.value).message})`}</span>
            )}
          </label>
          <div>
            {(field.type === 'text' || field.type === 'number') && (
              <Input
                type={field.type}
                id={field.id}
                placeholder={field.placeholder}
                value={field.value}
                max={field.max}
                min={field.min}
                step={field.step}
                className=''
                onChange={e => field.callback && field.callback(field.name, e.target.value)}
                disabled={field.disableCallback ? field.disableCallback() : false}
              />
            )}

            {field.type === 'datetime-local' && (
              <Input
                type={field.type}
                id={field.id}
                placeholder={field.placeholder}
                value={field.value}
                onChange={e => field.callback(field.name, e.target.value)}
                disabled={field.disableCallback ? field.disableCallback() : false}
              />
            )}

            {field.type === 'date' && (
              <Input
                type={field.type}
                id={field.id}
                placeholder={field.placeholder}
                value={field.value}
                onChange={e => field.callback(field.name, e.target.value)}
                disabled={field.disableCallback ? field.disableCallback() : false}
              />
            )}

            {field.type === 'combobox' && field.combobox && (
              <>
                <Combobox
                  items={field.combobox.items ? field.combobox.items : []}
                  selected={field.value}
                  setSelected={e => field.callback(field.name, e)}
                  query={field.combobox.query ? field.combobox.query : undefined}
                  setQuery={field.combobox.setQuery ? field.combobox.setQuery : undefined}
                  disabled={field.disableCallback ? field.disableCallback() : false}
                  placeholder={field.placeholder}
                />
              </>
            )}

            {field.type === 'select' && field.selectbox && (
              <>
                <select
                  id='location'
                  name='location'
                  value={field.value}
                  className={
                    'capitalize block w-full shadow-sm focus:ring-cego-lightbrown focus:border-cego-lightbrown font-light text-base border-cego-lightbrown rounded-sm py-2.5'
                  }
                  onChange={e => field.callback(field.name, e.target.value)}
                  disabled={field.disableCallback && !field.disableCallback()}
                >
                  <option value='kies'>Kies</option>
                  {field.selectbox.items?.split(',').map((item: any, index: number) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
                </select>
              </>
            )}

            {field.type === 'editor' && (
              <>
                <div className={classNames('sweet-loading my-10 text-center', isTimeOut ? 'hidden' : 'block')}>
                  <BeatLoader color={'#A9C1C9'} loading={true} size={15} aria-label='Loading Spinner' data-testid='loader' />
                </div>
                <div className={classNames(isTimeOut ? 'block' : 'hidden')}>
                  <Editor
                    apiKey='y8v65wfkd6uxajrh03buxibzkyrxwywwe3ggcccybjg1ry4d'
                    init={{
                      height: 317,
                      menubar: true,
                      menu: {
                        file: { title: 'File', items: 'newdocument' },
                        edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall' },
                        insert: { title: 'Insert', items: 'link emplate' },
                        format: { title: 'Format', items: 'bold italic underline' },
                      },
                      plugins: [
                        'advlist',
                        'autolink',
                        'lists',
                        'link',
                        'image',
                        'charmap',
                        'preview',
                        'anchor',
                        'searchreplace',
                        'visualblocks',
                        'code',
                        'fullscreen',
                        'insertdatetime',
                        'media',
                        'table',
                        'help',
                        'wordcount',
                      ],
                      toolbar:
                        'undo redo | blocks | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    }}
                    value={field.value}
                    onEditorChange={e => {
                      field.callback(field.name, e);
                    }}
                  />
                </div>
              </>
            )}

            {field.type === 'stringList' && <StringList list={field.value} callback={field.callback} name={field.name} />}

            {field.type === 'quoteList' && <QuoteList list={field.value} callback={field.callback} name={field.name} />}

            {field.type === 'extraDates' && (
              <ExtraDateList
                list={field.value}
                callback={field.callback}
                name={field.name}
                disabled={field.disableCallback ? field.disableCallback() : false}
              />
            )}

            {field.type === 'dropzone' && field.dropzone && (
              <FileUpload selectedFile={field.dropzone.selectedFile} setSelectedFile={field.dropzone.setSelectedFile} value={field.value} />
            )}

            {field.type === 'multidropzone' && field.multidropzone && (
              <MultiFileUpload
                selectedFiles={field.multidropzone.selectedFiles}
                setSelectedFiles={field?.multidropzone.setSelectedFiles}
                value={field.value}
              />
            )}

            {field.type === 'textarea' && (
              <Textarea
                id={field.id}
                placeholder={field.placeholder}
                value={field.value}
                onChange={e => field.callback(field.name, e.target.value)}
                disabled={field.disableCallback ? field.disableCallback() : false}
                style={{ width: '100%' }}
              />
            )}

            {field.type === 'switch' && (
              <Switch
                checked={field.value ? field.value : false}
                onChange={e => field.callback(field.name, e)}
                disabled={field.disableCallback && !field.disableCallback()}
              />
            )}

            {field.type === 'table' && field.table && field && (
              <>
                {(!field.table.showCreateCallback || field.table.showCreateCallback() === true) && (
                  <Button quaternary onClick={() => (field.table && field.table.onCreate ? field.table.onCreate(undefined) : undefined)}>
                    Nieuw{' '}
                    <span className='inline ml-1'>
                      <PlusIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
                    </span>
                  </Button>
                )}
                <Table
                  items={field.table ? field.table.items : []}
                  columns={field.table ? field.table.columns : []}
                  onSelect={field.table ? field.table.onSelect : undefined}
                  selection={field.table ? field.table.selection : undefined}
                  loading={field.table ? field.table.loading : undefined}
                  lazyLoading={field.table ? field.table.lazyLoading : undefined}
                  loadMore={field.table ? field.table.loadMore : undefined}
                  loadMoreCallback={field.table ? field.table.loadMoreCallback : undefined}
                />
              </>
            )}

            {field.type === 'fast-table' && field.fasttable && field && (
              <>
                <Table items={field.fasttable ? field.fasttable.items : []} columns={field.fasttable ? field.fasttable.columns : []} />
              </>
            )}

            {field.type === 'custom-button' && field.callback && (
              <>
                <Button
                  quaternary
                  onClick={() => field.callback('', '')}
                  disabled={field.disableCallback && field.disableCallback()}
                  className='mt-6'
                >
                  {field?.custombutton?.text} <span className='inline ml-1'>{field?.custombutton?.icon}</span>
                </Button>
              </>
            )}

            {field.type === 'custom-3' && field.onClick && field.value && (
              <>
                <CheckboxComponent onClick={field.onClick} value={field.value} />
              </>
            )}

            {field.type === 'add-button' && field.callback && (
              <>
                <Button
                  quaternary
                  onClick={() => field.callback('', '')}
                  disabled={field.disableCallback && field.disableCallback()}
                  className='mt-6'
                >
                  Toevoegen{' '}
                  <span className='inline ml-1'>
                    <PlusIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
                  </span>
                </Button>
              </>
            )}

            {field.type === 'link-button' && field.linkbutton && (
              <>
                <Link to={field.linkbutton.link}>
                  <Button quaternary disabled={field.disableCallback && field.disableCallback()} className='mt-6'>
                    {field.linkbutton.text}{' '}
                    <span className='inline ml-1'>
                      <PaperAirplaneIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
                    </span>
                  </Button>
                </Link>
              </>
            )}

            {field.type === 'attachments' && field && field.value && (
              <>
                <div className='pt-2'>
                  <div className='grid grid-cols-1 sm:grid-cols-4 md:grid-cols-6 gap-4 cursor-pointer'>
                    {field.value.map((item: any, index: number) => (
                      <div
                        className='p-4 border border-cego-lightbrown rounded-sm text-center'
                        onClick={async () => {
                          try {
                            const res = await toast.promise(
                              new Promise(async (resolve, reject) => {
                                const token = await getAccessTokenSilently();
                                fetch(`${process.env.REACT_APP_FILE_URI}/files/downloadAttachment/${item}`, {
                                  method: 'GET',
                                  headers: new Headers({
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'application/json',
                                  }),
                                })
                                  .then(response => response.blob())
                                  .then(blob => {
                                    const url = window.URL.createObjectURL(new Blob([blob]));
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.setAttribute('download', item);
                                    document.body.appendChild(link);
                                    link.click();
                                    link.remove();
                                    resolve(item);
                                  })

                                  .catch(error => {
                                    console.error(error);
                                  });

                                resolve(undefined);
                              }),
                              {
                                pending: {
                                  position: DEFAULT_TOAST_POSITION,
                                  render() {
                                    return DEFAULT_LOADING_MESSAGE;
                                  },
                                },
                                error: {
                                  position: DEFAULT_TOAST_POSITION,
                                  render() {
                                    return DEFAULT_ERROR_MESSAGE;
                                  },
                                  icon: FiAlertCircle,
                                },
                              },
                              {
                                autoClose: DEFAULT_TOAST_DURATION,
                              },
                            );

                            // const data = await resend.emails.send({
                            //   from: 'onboarding@resend.dev',
                            //   to: 'delivered@resend.dev',
                            //   subject: 'Hello World',
                            //   html: '<strong>It works!</strong>',
                            // });

                            toastSuccess('Bijlage succesvol gedownload');
                          } catch (error) {
                            toastError('Er is iets misgegaan bij het versturen van de offerte');
                          }
                        }}
                      >
                        <div>
                          <DocumentIcon className='inline h-10 w-10 text-cego-lightbrown margin-auto' aria-hidden='true' />
                          <div className='text-xs text-cego-lightbrown'>{item}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}

            {field.type === 'detail' && field.detail && (
              <Modal open={field.detail.open} setOpen={field.detail.setOpen}>
                <>
                  {field.detail.open && ( // important condition to reset detail state
                    <DetailForm schema={field.detail.schema} title={field.detail.title} caption={field.detail.caption} />
                  )}
                </>
              </Modal>
            )}

            {field.type === 'roles' && (
              <RolesComponent roles={CEGO_ROLES} userRoles={field.value ? field.value : []} callback={field.callback} name={field.name} />
            )}

            {field.type === 'title' && (
              <>
                <h2 className='w-full font-medium text-lg'>{field.value}</h2>
              </>
            )}

            {field.type === 'custom' && field.custom && (
              <div className=' bg-cego-offwhite rounded-sm p-4'>
                <div className='flex justify-between'>
                  <div>
                    <h2 className='text-md font-medium mb-1'>{field.value.event.location.name}</h2>
                    <p className='font-light text-base tracking-wide mb-1'>
                      {field.value.createdAt ? moment(field.value.createdAt).locale('nl-be').format('LLL') : ''}
                    </p>
                    <p className='font-medium text-base tracking-wide'>{resolveMoney(field.value.totalPrice)}</p>
                  </div>

                  <div className='flex'>
                    <h2 className='text-right text-xl font-medium'>
                      {field?.value?.poNo && `Bestelbonnummer:[${field.value.poNo}]`} {`Ordernummer:[${field.value.id}]`}
                    </h2>
                  </div>
                </div>

                <div>
                  <div className='flex justify-between mb-0.5'>
                    <div className='bg-white p-4 rounded-sm my-4' style={{ width: `${33}%` }}>
                      <p className='font-medium pb-0.5'>
                        {field.value.firstName} {field.value.lastName}
                      </p>
                      {field.value.phone && <span className='block text-sm pb-0.5'>{field.value.email}</span>}
                      {field.value.phone && <span className='block text-sm'>{field.value.customer.phone}</span>}
                    </div>
                    <div className='bg-white p-4 rounded-sm my-4' style={{ width: `${65}%` }}>
                      <div>
                        <p className='font-medium pb-0.5'>Opmerkingen</p>
                        <p className='block text-sm pb-0.5'>{field.value.comments}</p>
                        <hr />
                        <div className='flex pt-2'>
                          <div>
                            Marketing: <span className='font-bold'>{field.value.allowMarketing === true ? 'Ja' : 'Nee'}</span>
                          </div>
                          <div className='ml-8'>
                            Foto&apos;s en filmpjes: <span className='font-bold'>{field.value.allowPhotos === true ? 'Ja' : 'Nee'}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='flex justify-between'>
                  <div className=' bg-white p-4 px-5 rounded-sm cursor-pointer' style={{ width: `${49}%` }}>
                    <dt className='font-medium text-gray-900'>Organisatie</dt>
                    <dd className='mt-3 text-gray-500 mb-4'>
                      <span className='block font-medium'>
                        {(field.value?.embeddedCustomer &&
                          (() => {
                            try {
                              const parsed = JSON.parse(field.value.embeddedCustomer);
                              return `${parsed.institution} ${parsed.institutionID ? `(${parsed.institutionID})` : ''}`;
                            } catch {
                              return `${field.value?.customer?.institution} ${
                                field.value?.customer?.institutionID ? `(${field.value?.customer?.institutionID})` : ''
                              }`;
                            }
                          })()) ||
                          `${field.value?.customer?.institution} ${
                            field.value?.customer?.institutionID ? `(${field.value?.customer?.institutionID})` : ''
                          }`}
                      </span>
                      {(() => {
                        if (field.value?.embeddedCustomer) {
                          try {
                            const parsed = JSON.parse(field.value.embeddedCustomer);
                            return (
                              <>
                                {parsed.billingID && <span className='block text-sm'>{parsed.billingID}</span>}
                                {parsed.email && <span className='block text-sm'>{parsed.email}</span>}
                                {parsed.phone && <span className='block text-sm'>{parsed.phone}</span>}
                              </>
                            );
                          } catch {
                            // Fall back to customer if parsing fails
                          }
                        }
                        return (
                          <>
                            {field.value?.customer?.billingID && <span className='block text-sm'>{field.value.customer.billingID}</span>}
                            {field.value?.customer?.email && <span className='block text-sm'>{field.value.customer.email}</span>}
                            {field.value?.customer?.phone && <span className='block text-sm'>{field.value.customer.phone}</span>}
                          </>
                        );
                      })()}
                    </dd>

                    <dd className='mt-3 text-gray-500'>
                      {(() => {
                        if (field.value?.embeddedCustomer) {
                          try {
                            const parsed = JSON.parse(field.value.embeddedCustomer);
                            const address = typeof parsed.address === 'string' ? JSON.parse(parsed.address) : parsed.address;
                            return (
                              <>
                                <span className='block font-medium'>{address.address}</span>
                                <span className='block text-sm'>
                                  {address.zipcode} {address.city}
                                </span>
                                <span className='block text-sm'>{address.country}</span>
                              </>
                            );
                          } catch {
                            // Fall back to customer if parsing fails
                          }
                        }
                        return (
                          <>
                            <span className='block font-medium'>{field.value?.customer?.address?.address}</span>
                            <span className='block text-sm'>
                              {field.value?.customer?.address?.zipcode} {field.value?.customer?.address?.city}
                            </span>
                            <span className='block text-sm'>{field.value?.customer?.address?.country}</span>
                          </>
                        );
                      })()}
                    </dd>
                  </div>

                  <div className=' bg-white p-4 px-5 rounded-sm cursor-pointer' style={{ width: `${49}%` }}>
                    <dt className='font-medium text-gray-900'>Facturatie</dt>
                    <dd className='mt-3 text-gray-500 mb-4'>
                      {(() => {
                        // First check if we have a billing customer scenario
                        if (field.value?.customer?.billingCustomer || field.value?.embeddedInvoiceCustomer) {
                          try {
                            if (field.value?.embeddedInvoiceCustomer) {
                              const parsed = JSON.parse(field.value.embeddedInvoiceCustomer);
                              const address = typeof parsed.address === 'string' ? JSON.parse(parsed.address) : parsed.address;
                              return (
                                <>
                                  <span className='block font-medium'>{parsed.institution}</span>
                                  {parsed.billingID && <span className='block text-sm'>{parsed.billingID}</span>}
                                  {parsed.email && <span className='block text-sm'>{parsed.email}</span>}
                                  {parsed.phone && <span className='block text-sm'>{parsed.phone}</span>}
                                  <div className='mt-3'>
                                    {(() => {
                                      return (
                                        <>
                                          <span className='block font-medium'>{address.address}</span>
                                          <span className='block text-sm'>
                                            {address.zipcode} {address.city}
                                          </span>
                                          <span className='block text-sm'>{address.country}</span>
                                        </>
                                      );
                                    })()}
                                  </div>
                                </>
                              );
                            }
                          } catch {
                            // Fall back to billingCustomer if parsing fails
                          }

                          if (field.value?.customer?.billingCustomer) {
                            return (
                              <>
                                <span className='block font-medium'>{field.value.customer.billingCustomer.institution}</span>
                                {field.value.customer.billingCustomer.billingID && (
                                  <span className='block text-sm'>{field.value.customer.billingCustomer.billingID}</span>
                                )}
                                {field.value.customer.billingCustomer.email && (
                                  <span className='block text-sm'>{field.value.customer.billingCustomer.email}</span>
                                )}
                                {field.value.customer.billingCustomer.phone && (
                                  <span className='block text-sm'>{field.value.customer.billingCustomer.phone}</span>
                                )}
                                <div className='mt-3'>
                                  <span className='block font-medium'>{field.value.customer.billingCustomer.address.address}</span>
                                  <span className='block text-sm'>
                                    {field.value.customer.billingCustomer.address.zipcode}{' '}
                                    {field.value.customer.billingCustomer.address.city}
                                  </span>
                                  <span className='block text-sm'>{field.value.customer.billingCustomer.address.country}</span>
                                </div>
                              </>
                            );
                          }
                        }

                        // If no billing customer, use embedded customer or fall back to regular customer
                        try {
                          if (field.value?.embeddedCustomer) {
                            const parsed = JSON.parse(field.value.embeddedCustomer);
                            const address = typeof parsed.address === 'string' ? JSON.parse(parsed.address) : parsed.address;
                            return (
                              <>
                                <span className='block font-medium'>{parsed.institution}</span>
                                {parsed.billingID && <span className='block text-sm'>{parsed.billingID}</span>}
                                {parsed.email && <span className='block text-sm'>{parsed.email}</span>}
                                {parsed.phone && <span className='block text-sm'>{parsed.phone}</span>}
                                <div className='mt-3'>
                                  {(() => {
                                    return (
                                      <>
                                        <span className='block font-medium'>{address.address}</span>
                                        <span className='block text-sm'>
                                          {address.zipcode} {address.city}
                                        </span>
                                        <span className='block text-sm'>{address.country}</span>
                                      </>
                                    );
                                  })()}
                                </div>
                              </>
                            );
                          }
                        } catch {
                          // Fall back to customer if parsing fails
                        }

                        return (
                          <>
                            <span className='block font-medium'>{field.value?.customer?.institution}</span>
                            {field.value?.customer?.billingID && <span className='block text-sm'>{field.value.customer.billingID}</span>}
                            {field.value?.customer?.email && <span className='block text-sm'>{field.value.customer.email}</span>}
                            {field.value?.customer?.phone && <span className='block text-sm'>{field.value.customer.phone}</span>}
                            <div className='mt-3'>
                              <span className='block font-medium'>{field.value?.customer?.address?.address}</span>
                              <span className='block text-sm'>
                                {field.value?.customer?.address?.zipcode} {field.value?.customer?.address?.city}
                              </span>
                              <span className='block text-sm'>{field.value?.customer?.address?.country}</span>
                            </div>
                          </>
                        );
                      })()}
                    </dd>
                  </div>
                </div>
              </div>
            )}

            {field.type === 'custom2' && field.custom && (
              <div className=' bg-cego-offwhite rounded-sm p-4'>
                <div className='flex justify-between'>
                  <div>
                    <h2 className='text-md font-medium mb-1'>
                      {field.value.name} <span className='capitalize'>{field?.value?.entity && `[${field?.value?.entity}]`}</span>
                    </h2>
                    {/* <p className='font-light text-base tracking-wide mb-1'>
                      {field.value.createdAt ? moment(field.value.createdAt).locale('nl-be').format('LLL') : ''}
                    </p> */}
                    <p className='font-medium text-base tracking-wide'>
                      Totaal:{resolveMoney(field.value.totalPrice)} | Totaal diensten:{resolveMoney(field.value.servicePrice)}
                    </p>
                  </div>

                  <div className='flex'>
                    <h2 className='text-right text-xl font-medium'>
                      {field?.value?.poNo && `Bestelbonnummer:[${field.value.poNo}]`} {`Aanvraagnummer:[${field.value.documentNo}]`}
                    </h2>
                  </div>
                </div>

                {/* <div>
                  <div className='flex justify-between mb-0.5'>
                    <div className='bg-white p-4 rounded-sm my-4' style={{ width: `${33}%` }}>
                      <p className='font-medium pb-0.5'>
                        {field.value.firstName} {field.value.lastName}
                      </p>
                      {field.value.phone && <span className='block text-sm pb-0.5'>{field.value.email}</span>}
                      {field.value.phone && <span className='block text-sm'>{field.value.customer.phone}</span>}
                    </div>
                    <div className='bg-white p-4 rounded-sm my-4' style={{ width: `${65}%` }}>
                      <p className='font-medium pb-0.5'>Opmerkingen</p>
                      <p className='block text-sm pb-0.5'>{field.value.comments}</p>
                    </div>
                  </div>
                </div> */}

                <div className='flex justify-between mt-3'>
                  <div className=' bg-white p-4 px-5 rounded-sm cursor-pointer' style={{ width: `${49}%` }}>
                    <dt className='font-medium text-gray-900'>Organisatie</dt>
                    <dd className='mt-3 text-gray-500 mb-4'>
                      <span className='block font-medium'>
                        {(field.value?.embeddedCustomer &&
                          (() => {
                            try {
                              const parsed = JSON.parse(field.value.embeddedCustomer);
                              return `${parsed.institution} ${parsed.institutionID ? `(${parsed.institutionID})` : ''}`;
                            } catch {
                              return `${field.value?.customer?.institution} ${
                                field.value?.customer?.institutionID ? `(${field.value?.customer?.institutionID})` : ''
                              }`;
                            }
                          })()) ||
                          `${field.value?.customer?.institution} ${
                            field.value?.customer?.institutionID ? `(${field.value?.customer?.institutionID})` : ''
                          }`}
                      </span>
                      {(() => {
                        if (field.value?.embeddedCustomer) {
                          try {
                            const parsed = JSON.parse(field.value.embeddedCustomer);
                            return (
                              <>
                                {parsed.billingID && <span className='block text-sm'>{parsed.billingID}</span>}
                                {parsed.email && <span className='block text-sm'>{parsed.email}</span>}
                                {parsed.phone && <span className='block text-sm'>{parsed.phone}</span>}
                              </>
                            );
                          } catch {
                            // Fall back to customer if parsing fails
                          }
                        }
                        return (
                          <>
                            {field.value?.customer?.billingID && <span className='block text-sm'>{field.value.customer.billingID}</span>}
                            {field.value?.customer?.email && <span className='block text-sm'>{field.value.customer.email}</span>}
                            {field.value?.customer?.phone && <span className='block text-sm'>{field.value.customer.phone}</span>}
                          </>
                        );
                      })()}
                    </dd>

                    <dd className='mt-3 text-gray-500'>
                      {(() => {
                        if (field.value?.embeddedCustomer) {
                          try {
                            const parsed = JSON.parse(field.value.embeddedCustomer);
                            const address = typeof parsed.address === 'string' ? JSON.parse(parsed.address) : parsed.address;
                            return (
                              <>
                                <span className='block font-medium'>{address.address}</span>
                                <span className='block text-sm'>
                                  {address.zipcode} {address.city}
                                </span>
                                <span className='block text-sm'>{address.country}</span>
                              </>
                            );
                          } catch {
                            // Fall back to customer if parsing fails
                          }
                        }
                        return (
                          <>
                            <span className='block font-medium'>{field.value?.customer?.address?.address}</span>
                            <span className='block text-sm'>
                              {field.value?.customer?.address?.zipcode} {field.value?.customer?.address?.city}
                            </span>
                            <span className='block text-sm'>{field.value?.customer?.address?.country}</span>
                          </>
                        );
                      })()}
                    </dd>
                  </div>

                  <div className=' bg-white p-4 px-5 rounded-sm cursor-pointer' style={{ width: `${49}%` }}>
                    <dt className='font-medium text-gray-900'>Facturatie</dt>
                    <dd className='mt-3 text-gray-500 mb-4'>
                      {(() => {
                        // First check if we have a billing customer scenario
                        if (field.value?.customer?.billingCustomer || field.value?.embeddedInvoiceCustomer) {
                          try {
                            if (field.value?.embeddedInvoiceCustomer) {
                              const parsed = JSON.parse(field.value.embeddedInvoiceCustomer);
                              const address = typeof parsed.address === 'string' ? JSON.parse(parsed.address) : parsed.address;
                              return (
                                <>
                                  <span className='block font-medium'>{parsed.institution}</span>
                                  {parsed.billingID && <span className='block text-sm'>{parsed.billingID}</span>}
                                  {parsed.email && <span className='block text-sm'>{parsed.email}</span>}
                                  {parsed.phone && <span className='block text-sm'>{parsed.phone}</span>}
                                  <div className='mt-3'>
                                    {(() => {
                                      return (
                                        <>
                                          <span className='block font-medium'>{address.address}</span>
                                          <span className='block text-sm'>
                                            {address.zipcode} {address.city}
                                          </span>
                                          <span className='block text-sm'>{address.country}</span>
                                        </>
                                      );
                                    })()}
                                  </div>
                                </>
                              );
                            }
                          } catch {
                            // Fall back to billingCustomer if parsing fails
                          }

                          if (field.value?.customer?.billingCustomer) {
                            return (
                              <>
                                <span className='block font-medium'>{field.value.customer.billingCustomer.institution}</span>
                                {field.value.customer.billingCustomer.billingID && (
                                  <span className='block text-sm'>{field.value.customer.billingCustomer.billingID}</span>
                                )}
                                {field.value.customer.billingCustomer.email && (
                                  <span className='block text-sm'>{field.value.customer.billingCustomer.email}</span>
                                )}
                                {field.value.customer.billingCustomer.phone && (
                                  <span className='block text-sm'>{field.value.customer.billingCustomer.phone}</span>
                                )}
                                <div className='mt-3'>
                                  <span className='block font-medium'>{field.value.customer.billingCustomer.address.address}</span>
                                  <span className='block text-sm'>
                                    {field.value.customer.billingCustomer.address.zipcode}{' '}
                                    {field.value.customer.billingCustomer.address.city}
                                  </span>
                                  <span className='block text-sm'>{field.value.customer.billingCustomer.address.country}</span>
                                </div>
                              </>
                            );
                          }
                        }

                        // If no billing customer, use embedded customer or fall back to regular customer
                        try {
                          if (field.value?.embeddedCustomer) {
                            const parsed = JSON.parse(field.value.embeddedCustomer);
                            const address = typeof parsed.address === 'string' ? JSON.parse(parsed.address) : parsed.address;
                            return (
                              <>
                                <span className='block font-medium'>{parsed.institution}</span>
                                {parsed.billingID && <span className='block text-sm'>{parsed.billingID}</span>}
                                {parsed.email && <span className='block text-sm'>{parsed.email}</span>}
                                {parsed.phone && <span className='block text-sm'>{parsed.phone}</span>}
                                <div className='mt-3'>
                                  {(() => {
                                    return (
                                      <>
                                        <span className='block font-medium'>{address.address}</span>
                                        <span className='block text-sm'>
                                          {address.zipcode} {address.city}
                                        </span>
                                        <span className='block text-sm'>{address.country}</span>
                                      </>
                                    );
                                  })()}
                                </div>
                              </>
                            );
                          }
                        } catch {
                          // Fall back to customer if parsing fails
                        }

                        return (
                          <>
                            <span className='block font-medium'>{field.value?.customer?.institution}</span>
                            {field.value?.customer?.billingID && <span className='block text-sm'>{field.value.customer.billingID}</span>}
                            {field.value?.customer?.email && <span className='block text-sm'>{field.value.customer.email}</span>}
                            {field.value?.customer?.phone && <span className='block text-sm'>{field.value.customer.phone}</span>}
                            <div className='mt-3'>
                              <span className='block font-medium'>{field.value?.customer?.address?.address}</span>
                              <span className='block text-sm'>
                                {field.value?.customer?.address?.zipcode} {field.value?.customer?.address?.city}
                              </span>
                              <span className='block text-sm'>{field.value?.customer?.address?.country}</span>
                            </div>
                          </>
                        );
                      })()}
                    </dd>
                  </div>
                </div>
                <div className='bg-white p-4 rounded-sm mt-4' style={{ width: `${100}%` }}>
                  <div>
                    <div className='flex '>
                      <div>
                        Marketing: <span className='font-bold'>{field.value.allowMarketing === true ? 'Ja' : 'Nee'}</span>
                      </div>
                      <div className='ml-8'>
                        Foto&apos;s en filmpjes: <span className='font-bold'>{field.value.allowPhotos === true ? 'Ja' : 'Nee'}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {field.type === 'custom4' && field.custom && (
              <div className=' bg-cego-offwhite rounded-sm p-4'>
                <div className='flex justify-between'>
                  <div>
                    <h2 className='text-md font-medium mb-1'>{field.value.name}</h2>
                  </div>

                  <div className='flex'>
                    <h2 className='text-right text-xl font-medium'>
                      {field?.value?.poNo && `Bestelbonnummer:[${field.value.poNo}]`} {`Aanvraagnummer:[${field.value.documentNo}]`}
                    </h2>
                  </div>
                </div>

                <div className='flex justify-between mt-3'>
                  <div className=' bg-white p-4 px-5 rounded-sm cursor-pointer' style={{ width: `${49}%` }}>
                    <dt className='font-medium text-gray-900'>Organisatie</dt>
                    <dd className='mt-3 text-gray-500 mb-4'>
                      <span className='block font-medium'>
                        {(field.value?.embeddedCustomer &&
                          (() => {
                            try {
                              const parsed = JSON.parse(field.value.embeddedCustomer);
                              return `${parsed.institution} ${parsed.institutionID ? `(${parsed.institutionID})` : ''}`;
                            } catch {
                              return `${field.value?.customer?.institution} ${
                                field.value?.customer?.institutionID ? `(${field.value?.customer?.institutionID})` : ''
                              }`;
                            }
                          })()) ||
                          `${field.value?.customer?.institution} ${
                            field.value?.customer?.institutionID ? `(${field.value?.customer?.institutionID})` : ''
                          }`}
                      </span>
                      {(() => {
                        if (field.value?.embeddedCustomer) {
                          try {
                            const parsed = JSON.parse(field.value.embeddedCustomer);
                            return (
                              <>
                                {parsed.billingID && <span className='block text-sm'>{parsed.billingID}</span>}
                                {parsed.email && <span className='block text-sm'>{parsed.email}</span>}
                                {parsed.phone && <span className='block text-sm'>{parsed.phone}</span>}
                              </>
                            );
                          } catch {
                            // Fall back to customer if parsing fails
                          }
                        }
                        return (
                          <>
                            {field.value?.customer?.billingID && <span className='block text-sm'>{field.value.customer.billingID}</span>}
                            {field.value?.customer?.email && <span className='block text-sm'>{field.value.customer.email}</span>}
                            {field.value?.customer?.phone && <span className='block text-sm'>{field.value.customer.phone}</span>}
                          </>
                        );
                      })()}
                    </dd>

                    <dd className='mt-3 text-gray-500'>
                      {(() => {
                        if (field.value?.embeddedCustomer) {
                          try {
                            const parsed = JSON.parse(field.value.embeddedCustomer);
                            const address = typeof parsed.address === 'string' ? JSON.parse(parsed.address) : parsed.address;
                            return (
                              <>
                                <span className='block font-medium'>{address.address}</span>
                                <span className='block text-sm'>
                                  {address.zipcode} {address.city}
                                </span>
                                <span className='block text-sm'>{address.country}</span>
                              </>
                            );
                          } catch {
                            // Fall back to customer if parsing fails
                          }
                        }
                        return (
                          <>
                            <span className='block font-medium'>{field.value?.customer?.address?.address}</span>
                            <span className='block text-sm'>
                              {field.value?.customer?.address?.zipcode} {field.value?.customer?.address?.city}
                            </span>
                            <span className='block text-sm'>{field.value?.customer?.address?.country}</span>
                          </>
                        );
                      })()}
                    </dd>
                  </div>

                  <div className=' bg-white p-4 px-5 rounded-sm cursor-pointer' style={{ width: `${49}%` }}>
                    <dt className='font-medium text-gray-900'>Facturatie</dt>
                    <dd className='mt-3 text-gray-500 mb-4'>
                      {(() => {
                        // First check if we have a billing customer scenario
                        if (field.value?.customer?.billingCustomer || field.value?.embeddedInvoiceCustomer) {
                          try {
                            if (field.value?.embeddedInvoiceCustomer) {
                              const parsed = JSON.parse(field.value.embeddedInvoiceCustomer);
                              const address = typeof parsed.address === 'string' ? JSON.parse(parsed.address) : parsed.address;
                              return (
                                <>
                                  <span className='block font-medium'>{parsed.institution}</span>
                                  {parsed.billingID && <span className='block text-sm'>{parsed.billingID}</span>}
                                  {parsed.email && <span className='block text-sm'>{parsed.email}</span>}
                                  {parsed.phone && <span className='block text-sm'>{parsed.phone}</span>}
                                  <div className='mt-3'>
                                    {(() => {
                                      return (
                                        <>
                                          <span className='block font-medium'>{address.address}</span>
                                          <span className='block text-sm'>
                                            {address.zipcode} {address.city}
                                          </span>
                                          <span className='block text-sm'>{address.country}</span>
                                        </>
                                      );
                                    })()}
                                  </div>
                                </>
                              );
                            }
                          } catch {
                            // Fall back to billingCustomer if parsing fails
                          }

                          if (field.value?.customer?.billingCustomer) {
                            return (
                              <>
                                <span className='block font-medium'>{field.value.customer.billingCustomer.institution}</span>
                                {field.value.customer.billingCustomer.billingID && (
                                  <span className='block text-sm'>{field.value.customer.billingCustomer.billingID}</span>
                                )}
                                {field.value.customer.billingCustomer.email && (
                                  <span className='block text-sm'>{field.value.customer.billingCustomer.email}</span>
                                )}
                                {field.value.customer.billingCustomer.phone && (
                                  <span className='block text-sm'>{field.value.customer.billingCustomer.phone}</span>
                                )}
                                <div className='mt-3'>
                                  <span className='block font-medium'>{field.value.customer.billingCustomer.address.address}</span>
                                  <span className='block text-sm'>
                                    {field.value.customer.billingCustomer.address.zipcode}{' '}
                                    {field.value.customer.billingCustomer.address.city}
                                  </span>
                                  <span className='block text-sm'>{field.value.customer.billingCustomer.address.country}</span>
                                </div>
                              </>
                            );
                          }
                        }

                        // If no billing customer, use embedded customer or fall back to regular customer
                        try {
                          if (field.value?.embeddedCustomer) {
                            const parsed = JSON.parse(field.value.embeddedCustomer);
                            const address = typeof parsed.address === 'string' ? JSON.parse(parsed.address) : parsed.address;
                            return (
                              <>
                                <span className='block font-medium'>{parsed.institution}</span>
                                {parsed.billingID && <span className='block text-sm'>{parsed.billingID}</span>}
                                {parsed.email && <span className='block text-sm'>{parsed.email}</span>}
                                {parsed.phone && <span className='block text-sm'>{parsed.phone}</span>}
                                <div className='mt-3'>
                                  {(() => {
                                    return (
                                      <>
                                        <span className='block font-medium'>{address.address}</span>
                                        <span className='block text-sm'>
                                          {address.zipcode} {address.city}
                                        </span>
                                        <span className='block text-sm'>{address.country}</span>
                                      </>
                                    );
                                  })()}
                                </div>
                              </>
                            );
                          }
                        } catch {
                          // Fall back to customer if parsing fails
                        }

                        return (
                          <>
                            <span className='block font-medium'>{field.value?.customer?.institution}</span>
                            {field.value?.customer?.billingID && <span className='block text-sm'>{field.value.customer.billingID}</span>}
                            {field.value?.customer?.email && <span className='block text-sm'>{field.value.customer.email}</span>}
                            {field.value?.customer?.phone && <span className='block text-sm'>{field.value.customer.phone}</span>}
                            <div className='mt-3'>
                              <span className='block font-medium'>{field.value?.customer?.address?.address}</span>
                              <span className='block text-sm'>
                                {field.value?.customer?.address?.zipcode} {field.value?.customer?.address?.city}
                              </span>
                              <span className='block text-sm'>{field.value?.customer?.address?.country}</span>
                            </div>
                          </>
                        );
                      })()}
                    </dd>
                  </div>
                </div>
                <div className='bg-white p-4 rounded-sm mt-4' style={{ width: `${100}%` }}>
                  <div>
                    <div className='flex '>
                      <div>
                        Marketing: <span className='font-bold'>{field.value.allowMarketing === true ? 'Ja' : 'Nee'}</span>
                      </div>
                      <div className='ml-8'>
                        Foto&apos;s en filmpjes: <span className='font-bold'>{field.value.allowPhotos === true ? 'Ja' : 'Nee'}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {field.type === 'custom3' && field.custom && (
              <div className=' bg-cego-offwhite rounded-sm p-4'>
                <div className='flex justify-between'>
                  <div>
                    <h2 className='text-md font-medium mb-1'>{field.value.name}</h2>
                    {/* <p className='font-light text-base tracking-wide mb-1'>
                      {field.value.createdAt ? moment(field.value.createdAt).locale('nl-be').format('LLL') : ''}
                    </p> */}
                    <p className='font-medium text-base tracking-wide'>
                      Totaal:{resolveMoney(field.value.totalPrice)} | Totaal:{resolveMoney(field.value.servicePrice)}
                    </p>
                  </div>

                  <div className='flex'>
                    <h2 className='text-right text-xl font-medium'>
                      {field?.value?.poNo && `Bestelbonnummer:[${field.value.poNo}]`} {`Aanvraagnummer:[${field.value.documentNo}]`}
                    </h2>
                  </div>
                </div>

                {/* <div>
                  <div className='flex justify-between mb-0.5'>
                    <div className='bg-white p-4 rounded-sm my-4' style={{ width: `${33}%` }}>
                      <p className='font-medium pb-0.5'>
                        {field.value.firstName} {field.value.lastName}
                      </p>
                      {field.value.phone && <span className='block text-sm pb-0.5'>{field.value.email}</span>}
                      {field.value.phone && <span className='block text-sm'>{field.value.customer.phone}</span>}
                    </div>
                    <div className='bg-white p-4 rounded-sm my-4' style={{ width: `${65}%` }}>
                      <p className='font-medium pb-0.5'>Opmerkingen</p>
                      <p className='block text-sm pb-0.5'>{field.value.comments}</p>
                    </div>
                  </div>
                </div> */}

                <div className='flex justify-between mt-3'>
                  <div className=' bg-white p-4 px-5 rounded-sm cursor-pointer' style={{ width: `${49}%` }}>
                    <dt className='font-medium text-gray-900'>Organisatie</dt>
                    <dd className='mt-3 text-gray-500 mb-4'>
                      <span className='block font-medium'>
                        {(field.value?.embeddedCustomer &&
                          (() => {
                            try {
                              const parsed = JSON.parse(field.value.embeddedCustomer);
                              return `${parsed.institution} ${parsed.institutionID ? `(${parsed.institutionID})` : ''}`;
                            } catch {
                              return `${field.value?.customer?.institution} ${
                                field.value?.customer?.institutionID ? `(${field.value?.customer?.institutionID})` : ''
                              }`;
                            }
                          })()) ||
                          `${field.value?.customer?.institution} ${
                            field.value?.customer?.institutionID ? `(${field.value?.customer?.institutionID})` : ''
                          }`}
                      </span>
                      {(() => {
                        if (field.value?.embeddedCustomer) {
                          try {
                            const parsed = JSON.parse(field.value.embeddedCustomer);
                            return (
                              <>
                                {parsed.billingID && <span className='block text-sm'>{parsed.billingID}</span>}
                                {parsed.email && <span className='block text-sm'>{parsed.email}</span>}
                                {parsed.phone && <span className='block text-sm'>{parsed.phone}</span>}
                              </>
                            );
                          } catch {
                            // Fall back to customer if parsing fails
                          }
                        }
                        return (
                          <>
                            {field.value?.customer?.billingID && <span className='block text-sm'>{field.value.customer.billingID}</span>}
                            {field.value?.customer?.email && <span className='block text-sm'>{field.value.customer.email}</span>}
                            {field.value?.customer?.phone && <span className='block text-sm'>{field.value.customer.phone}</span>}
                          </>
                        );
                      })()}
                    </dd>

                    <dd className='mt-3 text-gray-500'>
                      {(() => {
                        if (field.value?.embeddedCustomer) {
                          try {
                            const parsed = JSON.parse(field.value.embeddedCustomer);
                            const address = typeof parsed.address === 'string' ? JSON.parse(parsed.address) : parsed.address;
                            return (
                              <>
                                <span className='block font-medium'>{address.address}</span>
                                <span className='block text-sm'>
                                  {address.zipcode} {address.city}
                                </span>
                                <span className='block text-sm'>{address.country}</span>
                              </>
                            );
                          } catch {
                            // Fall back to customer if parsing fails
                          }
                        }
                        return (
                          <>
                            <span className='block font-medium'>{field.value?.customer?.address?.address}</span>
                            <span className='block text-sm'>
                              {field.value?.customer?.address?.zipcode} {field.value?.customer?.address?.city}
                            </span>
                            <span className='block text-sm'>{field.value?.customer?.address?.country}</span>
                          </>
                        );
                      })()}
                    </dd>
                  </div>

                  <div className=' bg-white p-4 px-5 rounded-sm cursor-pointer' style={{ width: `${49}%` }}>
                    <dt className='font-medium text-gray-900'>Facturatie</dt>
                    <dd className='mt-3 text-gray-500 mb-4'>
                      {(() => {
                        // First check if we have a billing customer scenario
                        if (field.value?.customer?.billingCustomer || field.value?.embeddedInvoiceCustomer) {
                          try {
                            if (field.value?.embeddedInvoiceCustomer) {
                              const parsed = JSON.parse(field.value.embeddedInvoiceCustomer);
                              const address = typeof parsed.address === 'string' ? JSON.parse(parsed.address) : parsed.address;
                              return (
                                <>
                                  <span className='block font-medium'>{parsed.institution}</span>
                                  {parsed.billingID && <span className='block text-sm'>{parsed.billingID}</span>}
                                  {parsed.email && <span className='block text-sm'>{parsed.email}</span>}
                                  {parsed.phone && <span className='block text-sm'>{parsed.phone}</span>}
                                  <div className='mt-3'>
                                    {(() => {
                                      return (
                                        <>
                                          <span className='block font-medium'>{address.address}</span>
                                          <span className='block text-sm'>
                                            {address.zipcode} {address.city}
                                          </span>
                                          <span className='block text-sm'>{address.country}</span>
                                        </>
                                      );
                                    })()}
                                  </div>
                                </>
                              );
                            }
                          } catch {
                            // Fall back to billingCustomer if parsing fails
                          }

                          if (field.value?.customer?.billingCustomer) {
                            return (
                              <>
                                <span className='block font-medium'>{field.value.customer.billingCustomer.institution}</span>
                                {field.value.customer.billingCustomer.billingID && (
                                  <span className='block text-sm'>{field.value.customer.billingCustomer.billingID}</span>
                                )}
                                {field.value.customer.billingCustomer.email && (
                                  <span className='block text-sm'>{field.value.customer.billingCustomer.email}</span>
                                )}
                                {field.value.customer.billingCustomer.phone && (
                                  <span className='block text-sm'>{field.value.customer.billingCustomer.phone}</span>
                                )}
                                <div className='mt-3'>
                                  <span className='block font-medium'>{field.value.customer.billingCustomer.address.address}</span>
                                  <span className='block text-sm'>
                                    {field.value.customer.billingCustomer.address.zipcode}{' '}
                                    {field.value.customer.billingCustomer.address.city}
                                  </span>
                                  <span className='block text-sm'>{field.value.customer.billingCustomer.address.country}</span>
                                </div>
                              </>
                            );
                          }
                        }

                        // If no billing customer, use embedded customer or fall back to regular customer
                        try {
                          if (field.value?.embeddedCustomer) {
                            const parsed = JSON.parse(field.value.embeddedCustomer);
                            const address = typeof parsed.address === 'string' ? JSON.parse(parsed.address) : parsed.address;
                            return (
                              <>
                                <span className='block font-medium'>{parsed.institution}</span>
                                {parsed.billingID && <span className='block text-sm'>{parsed.billingID}</span>}
                                {parsed.email && <span className='block text-sm'>{parsed.email}</span>}
                                {parsed.phone && <span className='block text-sm'>{parsed.phone}</span>}
                                <div className='mt-3'>
                                  {(() => {
                                    return (
                                      <>
                                        <span className='block font-medium'>{address.address}</span>
                                        <span className='block text-sm'>
                                          {address.zipcode} {address.city}
                                        </span>
                                        <span className='block text-sm'>{address.country}</span>
                                      </>
                                    );
                                  })()}
                                </div>
                              </>
                            );
                          }
                        } catch {
                          // Fall back to customer if parsing fails
                        }

                        return (
                          <>
                            <span className='block font-medium'>{field.value?.customer?.institution}</span>
                            {field.value?.customer?.billingID && <span className='block text-sm'>{field.value.customer.billingID}</span>}
                            {field.value?.customer?.email && <span className='block text-sm'>{field.value.customer.email}</span>}
                            {field.value?.customer?.phone && <span className='block text-sm'>{field.value.customer.phone}</span>}
                            <div className='mt-3'>
                              <span className='block font-medium'>{field.value?.customer?.address?.address}</span>
                              <span className='block text-sm'>
                                {field.value?.customer?.address?.zipcode} {field.value?.customer?.address?.city}
                              </span>
                              <span className='block text-sm'>{field.value?.customer?.address?.country}</span>
                            </div>
                          </>
                        );
                      })()}
                    </dd>
                  </div>
                </div>
                <div className='bg-white p-4 rounded-sm mt-4' style={{ width: `${100}%` }}>
                  <div>
                    <div className='flex '>
                      <div>
                        Marketing: <span className='font-bold'>{field.value.allowMarketing === true ? 'Ja' : 'Nee'}</span>
                      </div>
                      <div className='ml-8'>
                        Foto&apos;s en filmpjes: <span className='font-bold'>{field.value.allowPhotos === true ? 'Ja' : 'Nee'}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {field.type === 'extraAttendees' &&
              field &&
              field.value.map((item: any, index: number) => (
                <div
                  className='flex flex-wrap justify-between w-full border-t border-cego-lightbrown mt-4 pt-2'
                  key={`${index}EXTRAATTENDEES`}
                >
                  <div className='py-2.5' style={{ width: `48.5%` }}>
                    <label htmlFor='' className='text-cego-darkdarkgray block ml-0.5 mb-0.5 text-xs font-medium leading-6'>
                      {`Voornaam [Extra ${index + 1}]s`}
                    </label>
                    <Input
                      type={'text'}
                      id={'firstName'}
                      value={item.firstName || ''}
                      onChange={e => {
                        const newAttendees = field.value.map((attendee: IAttendee, i: number) => {
                          if (i !== index) {
                            return attendee;
                          }

                          return {
                            ...attendee,
                            firstName: e.target.value,
                          };
                        });

                        field.callback(field.name, newAttendees);
                      }}
                    />
                  </div>

                  <div className='py-2.5' style={{ width: `48.5%` }}>
                    <label
                      htmlFor={`Achternaam [Extra ${index + 1}]`}
                      className='text-cego-darkdarkgray block ml-0.5 mb-0.5 text-xs font-medium leading-6'
                    >
                      {`Achternaam [Extra ${index + 1}]`}
                    </label>
                    <Input
                      type={'text'}
                      id={`Achternaam [Extra ${index + 1}]`}
                      value={item.lastName || ''}
                      onChange={e => {
                        const newAttendees = field.value.map((attendee: IAttendee, i: number) => {
                          if (i !== index) {
                            return attendee;
                          }

                          return {
                            ...attendee,
                            lastName: e.target.value,
                          };
                        });

                        field.callback(field.name, newAttendees);
                      }}
                    />
                  </div>

                  <div className='py-2.5' style={{ width: `48.5%` }}>
                    <label
                      htmlFor={`Email [Extra ${index + 1}]`}
                      className='text-cego-darkdarkgray block ml-0.5 mb-0.5 text-xs font-medium leading-6'
                    >
                      {`Email [Extra ${index + 1}]`}
                    </label>
                    <Input
                      type={'text'}
                      id={`Email [Extra ${index + 1}]`}
                      value={item?.email || ''}
                      onChange={e => {
                        const newAttendees = field.value.map((attendee: IAttendee, i: number) => {
                          if (i !== index) {
                            return attendee;
                          }

                          return {
                            ...attendee,
                            email: e.target.value,
                          };
                        });

                        field.callback(field.name, newAttendees);
                      }}
                    />
                  </div>

                  <div className='py-2.5' style={{ width: `48.5%` }}>
                    <label
                      htmlFor={`Telefoon [Extra ${index + 1}]`}
                      className='text-cego-darkdarkgray block ml-0.5 mb-0.5 text-xs font-medium leading-6'
                    >
                      {`Telefoon [Extra ${index + 1}]`}
                    </label>
                    <Input
                      type={'text'}
                      id={`Telefoon [Extra ${index + 1}]`}
                      value={item?.phone || ''}
                      onChange={e => {
                        const newAttendees = field.value.map((attendee: IAttendee, i: number) => {
                          if (i !== index) {
                            return attendee;
                          }

                          return {
                            ...attendee,
                            phone: e.target.value,
                          };
                        });

                        field.callback(field.name, newAttendees);
                      }}
                    />
                  </div>
                </div>
              ))}
          </div>
          {field.caption && <p className='ml-0.5 mt-1 text-xs font-light leading-6 text-cego-darkgray'>{field.caption}</p>}
        </div>
      )}
    </>
  );
};

export default DetailForm;
