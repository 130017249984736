import { useCallback, useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  ClipboardDocumentCheckIcon,
  ClipboardDocumentIcon,
  DocumentArrowDownIcon,
  DocumentIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import { FiAlertCircle } from 'react-icons/fi';
import { FaFileExcel, FaFilePdf, FaRegFileExcel, FaRegFilePdf } from 'react-icons/fa';
import moment from 'moment';
import { Font, PDFViewer, Page, View, Text, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { BeatLoader } from 'react-spinners';
import { LockClosedIcon } from '@heroicons/react/24/solid';
import { useAuth0 } from '@auth0/auth0-react';
import { IColumn, Table } from '../../components/tailwind/table';
import {
  CREATE_ACTIVITY,
  DELETE_ACTIVITY,
  GET_ACTIVITIES,
  GET_ACTIVITY,
  IActivity,
  IUpdateActivity,
  UPDATE_ACTIVITY,
} from '../../utils/activity';
import {
  CEGO_LOGO,
  DEFAULT_DATE_FORMAT_LONG,
  DEFAULT_DETAIL_OPEN,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_SELECTED_TAB,
  DEFAULT_SORTING,
  DEFAULT_TAKE,
  DEFAULT_TAKE_LOADMORE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  classNames,
  filterComboBoxUserItem,
  resolveEntityColor,
  resolveMoney,
  resolveValueForCombobox,
} from '../../utils';
import { Modal } from '../../components/tailwind/modal';
import DetailForm, { DetailSchema } from '../../components/detailForm';
import { IComboBoxOption, ISorting } from '../../types';
import Button from '../../components/tailwind/button';
import { SearchContext } from '../../context/searchContext';
import { toastError, toastSuccess } from '../../utils/toast';
import { CANCEL_EVENT, CLOSE_EVENT, CREATE_EVENT, DELETE_EVENT, GET_EVENT, GET_EVENTS, IEvent, UPDATE_EVENT } from '../../utils/event';
import { GET_ENTITIES, convertEntitiesToComboBoxOptions } from '../../utils/entity';
import { GET_ACTIVITY_TYPES } from '../../utils/activityType';
import { CREATE_TICKETTYPE, DELETE_TICKETTYPE, GET_TICKETTYPE, ITicketType, UPDATE_TICKETTYPE } from '../../utils/ticketType';
import { CREATE_ROOMTYPE, DELETE_ROOMTYPE, GET_ROOMTYPE, IRoomType, UPDATE_ROOMTYPE } from '../../utils/roomType';
import { CREATE_SESSIONTYPE, DELETE_SESSIONTYPE, GET_SESSIONTYPE, ISessionType, UPDATE_SESSIONTYPE } from '../../utils/sessionType';
import { IAttendee, UPDATE_ATTENDEE } from '../../utils/attendee';
import { GET_TARGET_AUDIENCE_TYPES, convertTargetAudienceTypesToComboBoxOptions } from '../../utils/targetAudienceType';
import { GET_THEME_TYPES, convertThemeTypesToComboBoxOptions } from '../../utils/themeType';
import { FullScreenModal } from '../../components/tailwind/fullscreenmodal';
import fontPoppins from '../../fonts/Poppins-Regular.ttf';
import fontPoppinsSemiBold from '../../fonts/Poppins-SemiBold.ttf';
import fontPoppinsBold from '../../fonts/Poppins-Bold.ttf';
import { GET_USERS, IUser, convertUsersToComboBoxOptions } from '../../utils/user';
import { CREATE_EVENT_USER, DELETE_EVENT_USER, IEventUser } from '../../utils/eventUser';
import { ConfirmationAnulationForm, ConfirmationForm } from '../../components/confirmationForm';
import FilterForm, { InlineFilter, initFilterPanelState } from '../../components/inlineFilterForm';
import { SmallModal } from '../../components/tailwind/smallmodal';
import { GET_VENUES, IVenue, convertVenuesToComboBoxOptions } from '../../utils/venue';
import { CLOSE_MAILINGLOG, GET_MAILING, IMailing, IMailingLog } from '../../utils/mailing';
import useDebounce from '../../components/tailwind/hooks/useDebounce';
import { GET_CUSTOMERS, ICustomer, convertCustomersToComboBoxOptions } from '../../utils/customer';

export const Events: React.FC = ({ ...props }) => {
  // ===========================================
  // ============== GENERAL STATE ==============

  const { getAccessTokenSilently, user } = useAuth0();

  const { searchQuery, setSearchQuery } = useContext(SearchContext);
  useEffect(() => {
    setSearchQuery('');
  }, []);

  const {
    loading: loadingUsers,
    error: errorUsers,
    data: dataUsers,
  } = useQuery(GET_USERS, {
    fetchPolicy: 'network-only',
  });

  const [selectedCustomer2, setSelectedCustomer2] = useState<any | undefined>(undefined);
  const [customerQueryString2, setCustomerQueryString2] = useState<string>('');
  const [debounceCustomerQueryString2, setDebounceCustomerQueryString2] = useState<string>(' ');
  const debouncedCustomerQuery2 = useDebounce(customerQueryString2, 500);

  useEffect(() => {
    setDebounceCustomerQueryString2(debouncedCustomerQuery2);
  }, [debouncedCustomerQuery2]);

  const getFiltersCustomerQuery2 = (filterString: string, selectedCustomer: ICustomer | undefined) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      AND: [],
    };

    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [{ institution: { contains: filterArray[i] } }],
      };

      filterObject.AND.push(filterValue);
    }

    if (selectedCustomer2 && selectedCustomer2.id) {
      const alteredFilterObject: any = {
        OR: [],
      };

      alteredFilterObject.OR.push({
        id: {
          equals: selectedCustomer2.id,
        },
      });
      alteredFilterObject.OR.push(filterObject);

      return alteredFilterObject;
    }

    return filterObject;
  };

  const {
    data: dataCustomers2,
    loading: loadingCustomers2,
    error: errorCustomers2,
    refetch: refetchCustomers2,
    fetchMore: fetchMoreCustomers2,
  } = useQuery(GET_CUSTOMERS, {
    variables: {
      filter: getFiltersCustomerQuery2(debounceCustomerQueryString2, selectedCustomer2),
      take: DEFAULT_TAKE,
    },
  });

  // -------------------------------------- DEBOUNCE COMBOBOX --------------------------------------
  const [selectedVenue, setSelectedVenue] = useState<IVenue | undefined>(undefined);
  const [venueQueryString, setVenueQueryString] = useState<string>('');
  const [debounceVenueQueryString, setDebounceVenueQueryString] = useState<string>(' ');
  const debouncedVenueQuery = useDebounce(venueQueryString, 500);

  const [selectedCustomer3, setSelectedCustomer3] = useState<any | undefined>(undefined);
  const [customerQueryString3, setCustomerQueryString3] = useState<string>('');
  const [debounceCustomerQueryString3, setDebounceCustomerQueryString3] = useState<string>(' ');
  const debouncedCustomerQuery3 = useDebounce(customerQueryString3, 500);

  const getFiltersCustomerQuery3 = (filterString: string, selectedCustomer: IUser | undefined) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      AND: [],
    };

    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [{ firstName: { contains: filterArray[i] } }, { lastName: { contains: filterArray[i] } }],
      };

      filterObject.AND.push(filterValue);
    }

    if (selectedCustomer3 && selectedCustomer3.id) {
      const alteredFilterObject: any = {
        OR: [],
      };

      alteredFilterObject.OR.push({
        id: {
          equals: selectedCustomer3.id,
        },
      });
      alteredFilterObject.OR.push(filterObject);

      return alteredFilterObject;
    }

    return filterObject;
  };

  const {
    data: dataCustomers3,
    loading: loadingCustomers3,
    error: errorCustomers3,
    refetch: refetchCustomers3,
    fetchMore: fetchMoreCustomers3,
  } = useQuery(GET_USERS, {
    variables: {
      filter: getFiltersCustomerQuery3(debounceCustomerQueryString3, selectedCustomer3),
      orderBy: {
        firstName: 'asc',
      },
    },
  });

  useEffect(() => {
    setDebounceVenueQueryString(debouncedVenueQuery);
  }, [debouncedVenueQuery]);

  const getFiltersVenueQuery = (filterString: string, selectedCustomer: IVenue | undefined) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      AND: [],
    };

    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [
          { name: { contains: filterArray[i] } },
          { address: { contains: filterArray[i] } },
          { zipcode: { contains: filterArray[i] } },
          { city: { contains: filterArray[i] } },
        ],
      };

      filterObject.AND.push(filterValue);
    }

    if (selectedVenue && selectedVenue.id) {
      const alteredFilterObject: any = {
        OR: [],
      };

      alteredFilterObject.OR.push({
        id: {
          equals: selectedVenue.id,
        },
      });
      alteredFilterObject.OR.push(filterObject);

      return alteredFilterObject;
    }

    return filterObject;
  };

  const {
    data: dataVenues,
    loading: loadingVenues,
    error: errorVenues,
    refetch: refetchVenues,
    fetchMore: fetchMoreVenues,
  } = useQuery(GET_VENUES, {
    variables: {
      filter: getFiltersVenueQuery(debounceVenueQueryString, selectedVenue),
      take: DEFAULT_TAKE,
    },
  });

  // ------------------------------------------------------------------------------------------------

  const [closeMailingLog] = useMutation(CLOSE_MAILINGLOG);

  const handleCloseMailingLog = async (id: number) => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (id) {
            closeMailingLog({
              variables: {
                id: id,
              },
              onCompleted: data => {
                // refetchEvent();
                resolve(data);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const sessionType: ISessionType = (await res) as any;

      refetchMailing();
      refetchEvent();

      toastSuccess('Log succesvol geupdate');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const [selectedMailing, setSelectedMailing] = useState<IMailing | undefined>();
  const [selectedMailingTab, setSelectedMailingTab] = useState(DEFAULT_SELECTED_TAB);
  const [sortingMailing, setSortingMailing] = useState<ISorting>(DEFAULT_SORTING);
  const [openMailingDetail, setOpenMailingDetail] = useState(DEFAULT_DETAIL_OPEN);
  const [selectedFiles, setSelectedFiles] = useState<File[] | null>(null);

  const mailingLogsColumns: IColumn[] = [
    {
      key: 'column2',
      name: 'Ontvanger',
      fieldName: 'ticketType.stock',
      render: (item: IMailingLog) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.recipient}</span>;
      },
    },
    {
      key: 'column3',
      name: 'Error',
      fieldName: 'sessionType.actualStock',
      render: (item: IMailingLog) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.resendResponse}</span>;
      },
    },
    {
      key: 'column4',
      name: 'Actie',
      fieldName: 'action',
      render: (item: IMailingLog) => {
        return (
          <span className='text-ellipsis text-base overflow-hidden block w-30 hover:text-cego-darkgray'>
            <TrashIcon
              className='inline w-5 h-5 cursor-pointer'
              onClick={() => {
                handleCloseMailingLog(item?.id as any); //WAYPOINT
              }}
            />
          </span>
        );
      },
    },
  ];

  const mailingColumns: IColumn[] = [
    {
      key: 'column0',
      name: 'Datum',
      fieldName: 'mailing.createdAt',
      render: (item: IMailing) => {
        return (
          <span className='text-ellipsis text-base overflow-hidden block w-30 cursor-pointer'>
            {moment(item?.createdAt).format(DEFAULT_DATE_FORMAT_LONG)}
            {item.hasError === true ? (
              <span className='absolute h-2 w-2'>
                <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-cego-red opacity-75'></span>
                <span className='absolute rounded-full h-2 w-2 bg-cego-red'></span>
              </span>
            ) : (
              ''
            )}
          </span>
        );
      },
    },
    {
      key: 'column1',
      name: 'Onderwerp',
      fieldName: 'mailing.subject',
      render: (item: IMailing) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30 cursor-pointer'>{`${item?.subject}`}</span>;
      },
    },
    {
      key: 'column2',
      name: 'Ontvangers',
      fieldName: 'mailing.recipients',
      render: (item: IMailing) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30 cursor-pointer'>{`${item?.recipients}`}</span>;
      },
    },
  ];

  const waitingListColumns: IColumn[] = [
    {
      key: 'column0',
      name: 'Naam',
      fieldName: 'waitingList.name',
      render: (item: IAttendee) => {
        return (
          <span className='text-ellipsis text-base overflow-hidden block w-30'>
            {item.firstName} {item.lastName}
          </span>
        );
      },
    },
    {
      key: 'column1',
      name: 'Email',
      fieldName: 'waitingList.email',
      render: (item: IAttendee) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item.email}</span>;
      },
    },
    {
      key: 'column2',
      name: 'Telefoonnummer',
      fieldName: 'waitingList.email',
      render: (item: IAttendee) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.phone}</span>;
      },
    },
  ];

  const [getMailing, { loading: loadingMailing, error: errorMailing, refetch: refetchMailing }] = useLazyQuery(GET_MAILING);

  const handleSetMailingSelection = (mailing?: IMailing) => {
    if (mailing?.id) {
      getMailing({
        variables: {
          where: {
            id: mailing?.id,
          },
        },
        onCompleted: data => {
          setSelectedMailing(data.findOneMailing);
        },
      });
    } else {
      setSelectedMailing(undefined);
    }

    setOpenMailingDetail(true);
  };

  const handleCreateMailing = async () => {
    try {
      const res = await toast.promise(
        new Promise(async (resolve, reject) => {
          const formData = new FormData();

          if (selectedFiles) {
            for (let i = 0; i < selectedFiles?.length; i++) {
              const element = selectedFiles[i];
              formData.append('files', element);
            }
          }

          formData.append(
            'data',
            JSON.stringify({
              auth0ID: user?.sub,
              eventID: selectedEvent?.id,
              subject: selectedMailing?.subject,
              body: selectedMailing?.body,
            }),
          );

          const token = await getAccessTokenSilently();
          fetch(`${process.env.REACT_APP_FILE_URI}/files/sendMailing`, {
            method: 'POST',
            body: formData,
            headers: new Headers({
              Authorization: `Bearer ${token}`,
            }),
          })
            .then(response => {
              if (response.ok) {
                resolve(undefined);
              } else {
                // Handle errors
                throw new Error(JSON.stringify(response));
              }
            })
            .catch(error => {
              console.error(error);
              reject(error);
            });
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      refetchEvent();
      setOpenMailingDetail(false);
      toastSuccess('Mailing succesvol verstuurd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message as string);
    }
  };

  const mailingDetailSchema: DetailSchema = {
    id: 'mailing_detail',
    object: selectedMailing,
    permission: '',
    selectedTab: selectedMailingTab,
    setSelectedTab: setSelectedMailingTab,
    handleCreate: handleCreateMailing,
    hideCreateCallback: () => {
      if (selectedEvent?.closed || selectedMailing?.id) {
        return true;
      }

      return false;
    },
    hideUpdateCallback: () => {
      return true;
    },
    sections: [
      {
        id: 'mailing_detail_section_0',
        title: 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'mailing_detail_section_0_field_0',
            label: 'Verzender',
            name: 'sender',
            required: true,
            value: selectedMailing?.sender,
            placeholder: '',
            type: 'text',
            width: 100,
            callback: (name: string, value: string) => {
              //
            },
            hideCallback: (value?: string) => {
              return selectedMailing?.id ? true : false;
            },
          },
          {
            id: 'mailing_detail_section_0_field_1',
            label: 'Ontvanger(s)',
            name: 'recipients',
            required: true,
            value: selectedMailing?.recipients ? JSON.stringify(selectedMailing?.recipients) : '',
            placeholder: '',
            type: 'textarea',
            width: 100,
            callback: (name: string, value: string) => {
              //
            },
            hideCallback: (value?: string) => {
              return selectedMailing?.id ? true : false;
            },
          },
          {
            id: 'mailing_detail_section_0_field_2',
            label: 'Onderwerp',
            name: 'subject',
            required: true,
            value: selectedMailing?.subject,
            placeholder: '',
            type: 'text',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedMailing((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: any): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Vul een onderwerp in',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'mailing_detail_section_0_field_3',
            label: 'Body',
            name: 'body',
            required: true,
            value: selectedMailing?.body,
            placeholder: '',
            type: 'editor',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedMailing((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: any): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Vul een body in',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'mailing_detail_section_0_field_4',
            label: 'Bijlagen',
            name: 'attachments',
            value: selectedMailing?.attachments,
            placeholder: '',
            type: 'attachments',
            width: 100,
            callback: (name: string, value: string) => {
              //
            },
            hideCallback: (value?: string) => {
              return selectedMailing?.id ? true : false;
            },
          },
          {
            id: 'mailing_detail_section_0_field_5',
            label: 'Bijlagen',
            name: 'attachments',
            value: selectedMailing?.attachments,
            //  required: true,
            type: 'multidropzone',
            multidropzone: {
              selectedFiles: selectedFiles,
              setSelectedFiles: setSelectedFiles,
            },
            width: 100,
            callback: (name: string, value: any) => {
              setSelectedMailing((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            hideCallback: (value?: string) => {
              return selectedMailing?.id ? false : true;
            },
          },
        ],
      },
      {
        id: 'mailing_detail_section_1',
        title: 'Logs',
        width: 100,
        fields: [
          {
            id: 'event_detail_section_1_field_0sdfxxzag',
            label: 'Logs',
            name: 'ticketTypes',
            value: selectedMailing?.mailingLogs,
            table: {
              columns: mailingLogsColumns,
              items: selectedMailing?.mailingLogs ? selectedMailing?.mailingLogs : [],
              showCreateCallback: () => {
                return false;
              },
            },
            type: 'table',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedEvent((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
        ],
        hideCallback: (value?: string) => {
          return selectedMailing?.id ? true : false;
        },
      },
    ],
  };

  // ===========================================
  // ============ EVENT USER STATE =============

  const [selectedEventUser, setSelectedEventUser] = useState<IEventUser | undefined>();
  const [createEventUser] = useMutation(CREATE_EVENT_USER);
  const [deleteEventUser] = useMutation(DELETE_EVENT_USER);

  const handleCreateEventUser = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedEventUser) {
            const createEventUserObj: any = {
              event: {
                connect: {
                  id: selectedEvent?.id,
                },
              },
              user: {
                connect: {
                  id: selectedEventUser?.id,
                },
              },
            };

            createEventUser({
              variables: {
                data: createEventUserObj,
              },
              onCompleted: data => {
                resolve(data.createEventUser);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      refetchEvent();

      toastSuccess('Medewerker succesvol toegevoegd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleDeleteEventUser = async (id: number) => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (id) {
            deleteEventUser({
              variables: {
                where: {
                  id,
                },
              },
              onCompleted: data => {
                resolve(undefined);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      refetchEvent(); // activitie
      toastSuccess('Medewerker succesvol verwijderd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const eventUserColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Voornaam',
      fieldName: 'propsalLineUser.user.firstName',
      render: (item: IEventUser) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.user?.firstName}</span>;
      },
    },
    {
      key: 'column2',
      name: 'Achternaam',
      fieldName: 'propsalLineUser.user.lastName',
      render: (item: IEventUser) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.user?.lastName}</span>;
      },
    },
    {
      key: 'column3',
      name: 'Functie',
      fieldName: 'propsalLineUser.user.jobTitle',
      render: (item: IEventUser) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.user?.jobTitle}</span>;
      },
    },
    {
      key: 'column4',
      name: 'Actie',
      fieldName: 'action',
      render: (item: IEventUser) => {
        return (
          <span className='text-ellipsis text-base overflow-hidden block w-30 hover:text-cego-darkgray'>
            <TrashIcon
              className='inline w-5 h-5 cursor-pointer'
              onClick={() => {
                handleDeleteEventUser(item?.id as any);
              }}
            />
          </span>
        );
      },
    },
  ];

  // ===========================================
  // ============ ATTENDEE STATE ===========

  const [updateAttendee] = useMutation(UPDATE_ATTENDEE);

  const handleUpdateAttendee = async (id: number, obj: any) => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (id) {
            const updateAttendeeTypeObj: any = {
              hasAttended: obj.hasAttended ? false : true,
              firstName: obj.firstName,
              lastName: obj.lastName,
              email: obj.email,
            };

            updateAttendee({
              variables: {
                id: id,
                data: updateAttendeeTypeObj,
              },
              onCompleted: data => {
                // refetchEvent();
                resolve(data);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const sessionType: ISessionType = (await res) as any;
      setSelectedSessionType(sessionType);
      refetchEvent();

      toastSuccess('Deelnemer succesvol geupdate');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const closedAttendeeColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Voornaam',
      fieldName: 'attendee.FirstName',
      render: (item: IAttendee) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.firstName}</span>;
      },
    },
    {
      key: 'column2',
      name: 'Achternaam',
      fieldName: 'attendee.LastName',
      render: (item: IAttendee) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.lastName}</span>;
      },
    },
    {
      key: 'column3',
      name: 'Aanwezig',
      fieldName: 'Aanwezig',
      render: (item: IAttendee) => {
        let color = '#ff8e3b';
        let string = `text-cego-white text-xs font-medium px-2 py-1.5 rounded-xs cursor-pointer ring-offset-2 ring-2 ring-cego-orange`;

        if (item?.hasAttended) {
          string = `text-cego-white text-xs font-medium px-2 py-1.5 rounded-xs cursor-pointer ring-offset-2 ring-2 ring-cego-green`;
          color = '#20A558';
        }

        if (selectedEvent?.closed) {
          string = `text-cego-white text-xs font-medium px-2 py-1.5 rounded-xs`;
          color = '#A9C1C9';
        }

        return (
          <span className={'text-ellipsis overflow-hidden w-40 tracking-widest'}>
            <span className={string} style={{ backgroundColor: color }}>
              <span className={!item.hasAttended ? 'px-1 text-center' : ''}>{item?.hasAttended === true ? 'Aanwezig' : 'Afwezig'}</span>
            </span>
          </span>
        );
      },
    },
    {
      key: 'column6',
      name: 'Attest',
      fieldName: 'Actie',
      render: (item: IAttendee) => {
        if (item.hasAttended) {
          return (
            <span className='text-ellipsis text-base overflow-hidden block w-30'>
              <span
                className='inline w-6 h-6 cursor-pointer hover:text-cego-darkgray'
                onClick={async () => {
                  try {
                    const res = await toast.promise(
                      new Promise(async (resolve, reject) => {
                        const token = await getAccessTokenSilently();
                        fetch(`${process.env.REACT_APP_FILE_URI}/files/downloadAtest/${item.id}`, {
                          method: 'GET',
                          headers: new Headers({
                            Authorization: `Bearer ${token}`,
                          }),
                        })
                          .then(response => response.blob())
                          .then(blob => {
                            const url = window.URL.createObjectURL(new Blob([blob]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', item.firstName + ' ' + item.lastName + 'aanwezigheidsattest' + '.pdf');
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                            resolve(item);
                          })

                          .catch(error => {
                            console.error(error);
                          });

                        resolve(undefined);
                      }),
                      {
                        pending: {
                          position: DEFAULT_TOAST_POSITION,
                          render() {
                            return DEFAULT_LOADING_MESSAGE;
                          },
                        },
                        error: {
                          position: DEFAULT_TOAST_POSITION,
                          render() {
                            return DEFAULT_ERROR_MESSAGE;
                          },
                          icon: FiAlertCircle,
                        },
                      },
                      {
                        autoClose: DEFAULT_TOAST_DURATION,
                      },
                    );

                    // const data = await resend.emails.send({
                    //   from: 'onboarding@resend.dev',
                    //   to: 'delivered@resend.dev',
                    //   subject: 'Hello World',
                    //   html: '<strong>It works!</strong>',
                    // });

                    toastSuccess('Bijlage succesvol gedownload');
                  } catch (error) {
                    toastError('Er is iets misgegaan bij het versturen van de offerte');
                  }
                }}
              >
                Download
              </span>
            </span>
          );
        } else {
          return <span className='text-ellipsis text-base overflow-hidden block w-30'></span>;
        }
      },
    },
  ];

  const attendeeColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Voornaam',
      fieldName: 'attendee.FirstName',
      render: (item: IAttendee) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.firstName}</span>;
      },
    },
    {
      key: 'column2',
      name: 'Achternaam',
      fieldName: 'attendee.LastName',
      render: (item: IAttendee) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.lastName}</span>;
      },
    },
    {
      key: 'column3',
      name: 'Aanwezig',
      fieldName: 'Aanwezig',
      render: (item: IAttendee) => {
        let color = '#ff8e3b';
        let string = `text-cego-white text-xs font-medium px-2 py-1.5 rounded-xs cursor-pointer ring-offset-2 ring-2 ring-cego-orange`;

        if (item?.hasAttended) {
          string = `text-cego-white text-xs font-medium px-2 py-1.5 rounded-xs cursor-pointer ring-offset-2 ring-2 ring-cego-green`;
          color = '#20A558';
        }

        if (selectedEvent?.closed) {
          string = `text-cego-white text-xs font-medium px-2 py-1.5 rounded-xs`;
          color = '#A9C1C9';
        }

        return (
          <span className={'text-ellipsis overflow-hidden w-40 tracking-widest'}>
            <span
              className={string}
              style={{ backgroundColor: color }}
              onClick={async () => {
                handleUpdateAttendee(item?.id as any, item);
              }}
            >
              <span className={!item.hasAttended ? 'px-1 text-center' : ''}>{item?.hasAttended === true ? 'Aanwezig' : 'Afwezig'}</span>
            </span>
          </span>
        );
      },
    },
    {
      key: 'column7',
      name: 'Bestelling',
      fieldName: 'Actie 2',
      render: (item: IAttendee) => {
        return (
          <span className='text-ellipsis overflow-hidden w-40 tracking-widest'>
            <span className='text-cego-white text-xs font-medium px-2 py-1.5 rounded-xs cursor-pointer ring-offset-2 ring-2 ring-cego-red bg-cego-red'>
              <a href={`/bestellingen/${item?.tickets ? item?.tickets[0]?.orderID : undefined}`}>Bewerk</a>
            </span>
          </span>
        );
      },
    },
  ];

  // ===========================================
  // ============ SESSION TYPE STATE ===========

  const [selectedSessionType, setSelectedSessionType] = useState<ISessionType | undefined>();
  const [selectedSessionTypeTab, setSelectedSessionTypeTab] = useState(DEFAULT_SELECTED_TAB);
  const [sortingSessionTypes, setSortingSessionTypes] = useState<ISorting>(DEFAULT_SORTING);
  const [openSessionTypeDetail, setOpenSessionTypeDetail] = useState(DEFAULT_DETAIL_OPEN);

  const sessionTypesColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Name',
      fieldName: 'sessionType.name',
      render: (item: ISessionType) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.name}</span>;
      },
    },
    {
      key: 'column2',
      name: 'Stock',
      fieldName: 'sessionType.stock',
      render: (item: ISessionType) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.stock}</span>;
      },
    },
    {
      key: 'column3',
      name: 'Beschikbaar',
      fieldName: 'sessionType.actualStock',
      render: (item: ISessionType) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.actualStock}</span>;
      },
    },
  ];

  const [getSessionType, { loading: loadingSessionType, error: errorSessionType, refetch: refetchSessionType }] =
    useLazyQuery(GET_SESSIONTYPE);
  const [createSessionType] = useMutation(CREATE_SESSIONTYPE);
  const [updateSessionType] = useMutation(UPDATE_SESSIONTYPE);
  const [deleteSessionType] = useMutation(DELETE_SESSIONTYPE);

  const handleSetSessionTypeSelection = (sessionType?: ISessionType) => {
    if (sessionType?.id) {
      getSessionType({
        variables: {
          where: {
            id: sessionType?.id,
          },
        },
        onCompleted: data => {
          setSelectedSessionType(data.findOneSessionType);
        },
      });
    } else {
      setSelectedSessionType(undefined);
    }

    setOpenSessionTypeDetail(true);
  };

  const handleUpdateSessionType = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedSessionType?.id) {
            const updateSessionTypeObj: any = {
              name: selectedSessionType?.name,
              price: selectedSessionType?.price ? parseFloat(selectedSessionType?.price.toString()) : 0,
              stock: selectedSessionType?.stock,
              code: selectedSessionType?.code,
              criticalStockLevel: selectedSessionType?.criticalStockLevel ? selectedSessionType?.criticalStockLevel : undefined,
              availableFrom: selectedSessionType?.availableFrom ? moment(selectedSessionType?.availableFrom).toISOString() : undefined,
              availableTill: selectedSessionType?.availableTill ? moment(selectedSessionType?.availableTill).toISOString() : undefined,
              event: {
                connect: {
                  id: selectedEvent?.id,
                },
              },
            };

            updateSessionType({
              variables: {
                id: selectedSessionType?.id,
                data: updateSessionTypeObj,
              },
              onCompleted: data => {
                resolve(data.updateSessionType);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const sessionType: ISessionType = (await res) as any;
      setSelectedSessionType(sessionType);
      refetchEvent();
      setOpenSessionTypeDetail(false);
      toastSuccess('Sessie type succesvol geupdate');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleCreateSessionType = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (!selectedSessionType?.id) {
            const createSessionTypeObj: any = {
              name: selectedSessionType?.name,
              price: selectedSessionType?.price ? parseFloat(selectedSessionType?.price.toString()) : 0,
              stock: selectedSessionType?.stock,
              code: selectedSessionType?.code,
              criticalStockLevel: selectedSessionType?.criticalStockLevel ? selectedSessionType?.criticalStockLevel : undefined,
              availableFrom: selectedSessionType?.availableFrom ? moment(selectedSessionType?.availableFrom).toISOString() : undefined,
              availableTill: selectedSessionType?.availableTill ? moment(selectedSessionType?.availableTill).toISOString() : undefined,
              event: {
                connect: {
                  id: selectedEvent?.id,
                },
              },
            };

            createSessionType({
              variables: {
                data: createSessionTypeObj,
              },
              onCompleted: data => {
                resolve(data.createSessionType);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const sessionType: ISessionType = (await res) as any;
      setSelectedSessionType(sessionType);
      refetchEvent();
      setOpenSessionTypeDetail(false);
      toastSuccess('Sessie type succesvol aangemaakt');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleDeleteSessionType = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedSessionType?.id) {
            deleteSessionType({
              variables: {
                where: {
                  id: selectedSessionType?.id,
                },
              },
              onCompleted: data => {
                resolve(undefined);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      setSelectedSessionType(undefined);
      refetchEvent();
      setOpenSessionTypeDetail(false);

      toastSuccess('Sessie type succesvol verwijderd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const sessionTypeDetailSchema: DetailSchema = {
    id: 'session_type_detail',
    object: selectedSessionType,
    permission: '',
    selectedTab: selectedSessionTypeTab,
    setSelectedTab: setSelectedSessionTypeTab,
    handleUpdate: handleUpdateSessionType,
    handleCreate: handleCreateSessionType,
    handleDelete: handleDeleteSessionType,
    hideCreateCallback: () => {
      return selectedEvent?.closed ? true : false;
    },
    hideUpdateCallback: () => {
      return selectedEvent?.closed ? true : false;
    },
    hideDeleteCallback: () => {
      return selectedEvent?.closed ? true : false;
    },
    delete: {
      title: 'Sessie type verwijderen?',
      text: 'Weet je zeker dat je dit sessie type wilt verwijderen? Dit kan niet ongedaan worden gemaakt.',
    },
    sections: [
      {
        id: 'session_type_detail_section_0',
        title: 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'session_type_detail_section_0_field_0',
            label: 'Naam',
            name: 'name',
            required: true,
            value: selectedSessionType?.name,
            placeholder: '',
            type: 'text',
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
            width: 24,
            callback: (name: string, value: string) => {
              setSelectedSessionType((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Naam moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'session_type_detail_section_0_field_1',
            label: 'Code',
            name: 'code',
            required: true,
            value: selectedSessionType?.code,
            placeholder: '',
            type: 'text',
            width: 24,
            callback: (name: string, value: string) => {
              setSelectedSessionType((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Code moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'session_type_detail_section_0_field_2',
            label: 'Stock',
            name: 'stock',
            required: true,
            value: selectedSessionType?.stock,
            placeholder: '',
            type: 'number',
            width: 24,
            callback: (name: string, value: string) => {
              setSelectedSessionType((prevState: any) => ({
                ...prevState,
                [name]: parseFloat(value),
              }));
              return value;
            },
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Stock moet worden ingevuld',
                };
              }

              if (parseInt(value) < selectedSessionType!.amountSold) {
                return {
                  valid: false,
                  message: 'Stock kan niet lager zijn als het aantal al ingenomen sessies',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'session_type_detail_section_0_field_2',
            label: 'Stock bericht min aantal',
            name: 'criticalStockLevel',
            value: selectedSessionType?.criticalStockLevel,
            placeholder: '',
            type: 'number',
            width: 24,
            callback: (name: string, value: string) => {
              setSelectedSessionType((prevState: any) => ({
                ...prevState,
                [name]: parseInt(parseFloat(value).toFixed(0)),
              }));
              return value;
            },
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
          },
          {
            id: 'session_type_detail_section_0_field_4',
            label: 'Beschikbaar vanaf',
            name: 'availableFrom',
            value: selectedSessionType?.availableFrom ? moment(selectedSessionType?.availableFrom).format('YYYY-MM-DD') : '',
            type: 'date',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedSessionType((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
          },
          {
            id: 'session_type_detail_section_0_field_5',
            label: 'Beschikbaar tot',
            name: 'availableTill',
            value: selectedSessionType?.availableTill ? moment(selectedSessionType?.availableTill).format('YYYY-MM-DD') : '',
            type: 'date',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedSessionType((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
          },
        ],
      },
    ],
  };

  const [selectedRoomType, setSelectedRoomType] = useState<IRoomType | undefined>();
  const [selectedRoomTypeTab, setSelectedRoomTypeTab] = useState(DEFAULT_SELECTED_TAB);
  const [sortingRoomTypes, setSortingRoomTypes] = useState<ISorting>(DEFAULT_SORTING);
  const [openRoomTypeDetail, setOpenRoomTypeDetail] = useState(DEFAULT_DETAIL_OPEN);

  const roomTypesColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'roomType.name',
      render: (item: IRoomType) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.name}</span>;
      },
    },
    {
      key: 'column2',
      name: 'Aantal personen',
      fieldName: 'roomType.amountPersons',
      render: (item: IRoomType) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.amountPersons}</span>;
      },
    },
    {
      key: 'column3',
      name: 'Stock',
      fieldName: 'roomType.stock',
      render: (item: IRoomType) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.stock}</span>;
      },
    },
    {
      key: 'column4',
      name: 'Beschikbaar',
      fieldName: 'roomType.actualStock',
      render: (item: IRoomType) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.actualStock}</span>;
      },
    },
    {
      key: 'column5',
      name: 'Prijs',
      fieldName: 'roomType.price',
      render: (item: IRoomType) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.price && resolveMoney(item?.price)}</span>;
      },
    },
  ];

  const [getRoomType, { loading: loadingRoomType, error: errorRoomType, refetch: refetchRoomType }] = useLazyQuery(GET_ROOMTYPE);
  const [createRoomType] = useMutation(CREATE_ROOMTYPE);
  const [updateRoomType] = useMutation(UPDATE_ROOMTYPE);
  const [deleteRoomType] = useMutation(DELETE_ROOMTYPE);

  const handleSetRoomTypeSelection = (roomType?: IRoomType) => {
    if (roomType?.id) {
      getRoomType({
        variables: {
          where: {
            id: roomType?.id,
          },
        },
        onCompleted: data => {
          setSelectedRoomType(data.findOneRoomType);
        },
      });
    } else {
      setSelectedRoomType(undefined);
    }

    setOpenRoomTypeDetail(true);
  };

  const handleUpdateRoomType = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedRoomType?.id) {
            const updateRoomTypeObj: any = {
              name: selectedRoomType?.name,
              price: selectedRoomType?.price,
              stock: selectedRoomType?.stock,
              amountPersons: selectedRoomType?.amountPersons,
              code: selectedRoomType?.code,
              criticalStockLevel: selectedRoomType?.criticalStockLevel ? selectedRoomType?.criticalStockLevel : undefined,
              availableFrom: selectedRoomType?.availableFrom ? moment(selectedRoomType?.availableFrom).toISOString() : undefined,
              availableTill: selectedRoomType?.availableTill ? moment(selectedRoomType?.availableTill).toISOString() : undefined,
              event: {
                connect: {
                  id: selectedEvent?.id,
                },
              },
            };

            updateRoomType({
              variables: {
                id: selectedRoomType?.id,
                data: updateRoomTypeObj,
              },
              onCompleted: data => {
                resolve(data.updateRoomType);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const roomType: IRoomType = (await res) as any;
      setSelectedRoomType(roomType);
      refetchEvent();
      setOpenRoomTypeDetail(false);
      toastSuccess('Kamer type succesvol geupdate');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleCreateRoomType = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (!selectedRoomType?.id) {
            const createRoomTypeObj: any = {
              name: selectedRoomType?.name,
              price: selectedRoomType?.price,
              stock: selectedRoomType?.stock,
              amountPersons: selectedRoomType?.amountPersons,
              code: selectedRoomType?.code,
              criticalStockLevel: selectedRoomType?.criticalStockLevel ? selectedRoomType?.criticalStockLevel : undefined,
              availableFrom: selectedRoomType?.availableFrom ? moment(selectedRoomType?.availableFrom).toISOString() : undefined,
              availableTill: selectedRoomType?.availableTill ? moment(selectedRoomType?.availableTill).toISOString() : undefined,
              event: {
                connect: {
                  id: selectedEvent?.id,
                },
              },
            };

            createRoomType({
              variables: {
                data: createRoomTypeObj,
              },
              onCompleted: data => {
                resolve(data.createRoomType);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const roomType: IRoomType = (await res) as any;
      setSelectedRoomType(roomType);
      refetchEvent();
      setOpenRoomTypeDetail(false);
      toastSuccess('Kamer type succesvol aangemaakt');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleDeleteRoomType = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedRoomType?.id) {
            deleteRoomType({
              variables: {
                where: {
                  id: selectedRoomType?.id,
                },
              },
              onCompleted: data => {
                resolve(undefined);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      setSelectedRoomType(undefined);
      refetchEvent();
      setOpenRoomTypeDetail(false);

      toastSuccess('Kamer type succesvol verwijderd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const roomTypeDetailSchema: DetailSchema = {
    id: 'room_type_detail',
    object: selectedRoomType,
    permission: '',
    selectedTab: selectedRoomTypeTab,
    setSelectedTab: setSelectedRoomTypeTab,
    handleUpdate: handleUpdateRoomType,
    handleCreate: handleCreateRoomType,
    handleDelete: handleDeleteRoomType,
    hideCreateCallback: () => {
      return selectedEvent?.closed ? true : false;
    },
    hideUpdateCallback: () => {
      return selectedEvent?.closed ? true : false;
    },
    hideDeleteCallback: () => {
      return selectedEvent?.closed ? true : false;
    },
    delete: {
      title: 'Kamer type verwijderen?',
      text: 'Weet je zeker dat je dit kamer type wilt verwijderen? Dit kan niet ongedaan worden gemaakt.',
    },
    sections: [
      {
        id: 'room_type_detail_section_0',
        title: 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'room_type_detail_section_0_field_0',
            label: 'Naam',
            name: 'name',
            required: true,
            value: selectedRoomType?.name,
            placeholder: '',
            type: 'text',
            width: 32.5,
            callback: (name: string, value: string) => {
              setSelectedRoomType((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Naam moet worden ingevuld',
                };
              }

              return { valid: true };
            },
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
          },
          {
            id: 'room_type_detail_section_0_field_4',
            label: 'Aantal personen',
            name: 'amountPersons',
            required: true,
            value: selectedRoomType?.amountPersons,
            placeholder: '',
            type: 'number',
            width: 32.5,
            callback: (name: string, value: string) => {
              setSelectedRoomType((prevState: any) => ({
                ...prevState,
                [name]: parseInt(value),
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Aantal personen moet worden ingevuld',
                };
              }

              return { valid: true };
            },
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
          },
          {
            id: 'room_type_detail_section_0_field_1',
            label: 'Code',
            name: 'code',
            required: true,
            value: selectedRoomType?.code,
            placeholder: '',
            type: 'text',
            width: 32.5,
            callback: (name: string, value: string) => {
              setSelectedRoomType((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Code moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'room_type_detail_section_0_field_2',
            label: 'Stock',
            name: 'stock',
            required: true,
            value: selectedRoomType?.stock,
            placeholder: '',
            type: 'number',
            width: 32.5,
            callback: (name: string, value: string) => {
              setSelectedRoomType((prevState: any) => ({
                ...prevState,
                [name]: parseInt(value),
              }));
              return value;
            },
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Stock moet worden ingevuld',
                };
              }

              if (parseInt(value) < selectedRoomType!.amountSold) {
                return {
                  valid: false,
                  message: 'Stock kan niet lager zijn als het aantal al verkochte kamers',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'room_type_detail_section_0_field_2x',
            label: 'Stock bericht min aantal',
            name: 'criticalStockLevel',
            value: selectedRoomType?.criticalStockLevel,
            placeholder: '',
            type: 'number',
            width: 32.5,
            callback: (name: string, value: string) => {
              setSelectedRoomType((prevState: any) => ({
                ...prevState,
                [name]: parseInt(parseFloat(value).toFixed(0)),
              }));
              return value;
            },
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
          },
          {
            id: 'room_type_detail_section_0_field_3',
            label: 'Prijs',
            name: 'price',
            required: true,
            value: selectedRoomType?.price,
            placeholder: '',
            type: 'number',
            width: 32.5,
            callback: (name: string, value: string) => {
              setSelectedRoomType((prevState: any) => ({
                ...prevState,
                [name]: parseFloat(value),
              }));
              return value;
            },
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Prijs moet worden ingevuld',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'room_type_detail_section_0_field_4',
            label: 'Beschikbaar vanaf',
            name: 'availableFrom',
            value: selectedRoomType?.availableFrom ? moment(selectedRoomType?.availableFrom).format('YYYY-MM-DD') : '',
            type: 'date',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedRoomType((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
          },
          {
            id: 'room_type_detail_section_0_field_5',
            label: 'Beschikbaar tot',
            name: 'availableTill',
            value: selectedRoomType?.availableTill ? moment(selectedRoomType?.availableTill).format('YYYY-MM-DD') : '',
            type: 'date',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedRoomType((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
          },
        ],
      },
    ],
  };

  // ===========================================
  // ============ TICKET TYPE STATE ============

  const [selectedTicketType, setSelectedTicketType] = useState<ITicketType | undefined>();
  const [selectedTicketTypeTab, setSelectedTicketTypeTab] = useState(DEFAULT_SELECTED_TAB);
  const [sortingTicketTypes, setSortingTicketTypes] = useState<ISorting>(DEFAULT_SORTING);
  const [openTicketTypeDetail, setOpenTicketTypeDetail] = useState(DEFAULT_DETAIL_OPEN);

  const ticketTypesColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Name',
      fieldName: 'ticketType.name',
      render: (item: ITicketType) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.name}</span>;
      },
    },
    {
      key: 'column2',
      name: 'Stock',
      fieldName: 'ticketType.stock',
      render: (item: ITicketType) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.stock}</span>;
      },
    },
    {
      key: 'column3',
      name: 'Beschikbaar',
      fieldName: 'sessionType.actualStock',
      render: (item: ITicketType) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.actualStock}</span>;
      },
    },
    {
      key: 'column4',
      name: 'Prijs',
      fieldName: 'ticketType.price',
      render: (item: ITicketType) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.price && resolveMoney(item.price)}</span>;
      },
    },
  ];

  const [getTicketType, { loading: loadingTicketType, error: errorTicketType, refetch: refetchTicketType }] = useLazyQuery(GET_TICKETTYPE);
  const [createTicketType] = useMutation(CREATE_TICKETTYPE);
  const [updateTicketType] = useMutation(UPDATE_TICKETTYPE);
  const [deleteTicketType] = useMutation(DELETE_TICKETTYPE);

  const handleSetTicketTypeSelection = (ticketType?: ITicketType) => {
    if (ticketType?.id) {
      getTicketType({
        variables: {
          where: {
            id: ticketType?.id,
          },
        },
        onCompleted: data => {
          setSelectedTicketType(data.findOneTicketType);
        },
      });
    } else {
      setSelectedTicketType(undefined);
    }

    setOpenTicketTypeDetail(true);
  };

  const handleUpdateTicketType = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedTicketType?.id) {
            const updateTicketTypeObj: any = {
              name: selectedTicketType?.name,
              price: selectedTicketType?.price,
              stock: selectedTicketType?.stock,
              code: selectedTicketType?.code,
              criticalStockLevel: selectedTicketType?.criticalStockLevel ? selectedTicketType?.criticalStockLevel : undefined,
              availableFrom: selectedTicketType?.availableFrom ? moment(selectedTicketType?.availableFrom).toISOString() : undefined,
              availableTill: selectedTicketType?.availableTill ? moment(selectedTicketType?.availableTill).toISOString() : undefined,
              event: {
                connect: {
                  id: selectedEvent?.id,
                },
              },
            };

            updateTicketType({
              variables: {
                id: selectedTicketType?.id,
                data: updateTicketTypeObj,
              },
              onCompleted: data => {
                resolve(data.updateTicketType);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const ticketType: ITicketType = (await res) as any;
      setSelectedTicketType(ticketType);
      refetchEvent();
      setOpenTicketTypeDetail(false);
      toastSuccess('Ticket type succesvol geupdate');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleCreateTicketType = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (!selectedTicketType?.id) {
            const createTicketTypeObj: any = {
              name: selectedTicketType?.name,
              price: selectedTicketType?.price,
              stock: selectedTicketType?.stock,
              code: selectedTicketType?.code,
              criticalStockLevel: selectedTicketType?.criticalStockLevel ? selectedTicketType?.criticalStockLevel : undefined,
              availableFrom: selectedTicketType?.availableFrom ? moment(selectedTicketType?.availableFrom).toISOString() : undefined,
              availableTill: selectedTicketType?.availableTill ? moment(selectedTicketType?.availableTill).toISOString() : undefined,
              numberAdditionalAttendees: selectedTicketType?.numberAdditionalAttendees ? selectedTicketType?.numberAdditionalAttendees : 1,
              event: {
                connect: {
                  id: selectedEvent?.id,
                },
              },
            };

            createTicketType({
              variables: {
                data: createTicketTypeObj,
              },
              onCompleted: data => {
                resolve(data.createTicketType);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const ticketType: ITicketType = (await res) as any;
      setSelectedTicketType(ticketType);
      refetchEvent();
      setOpenTicketTypeDetail(false);
      toastSuccess('Ticket type succesvol aangemaakt');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleDeleteTicketType = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedTicketType?.id) {
            deleteTicketType({
              variables: {
                where: {
                  id: selectedTicketType?.id,
                },
              },
              onCompleted: data => {
                resolve(undefined);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      setSelectedTicketType(undefined);
      refetchEvent();
      setOpenTicketTypeDetail(false);

      toastSuccess('Ticket type succesvol verwijderd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const ticketTypeDetailSchema: DetailSchema = {
    id: 'ticket_type_detail',
    object: selectedTicketType,
    permission: '',
    selectedTab: selectedTicketTypeTab,
    setSelectedTab: setSelectedTicketTypeTab,
    handleUpdate: handleUpdateTicketType,
    handleCreate: handleCreateTicketType,
    handleDelete: handleDeleteTicketType,
    hideCreateCallback: () => {
      return selectedEvent?.closed ? true : false;
    },
    hideUpdateCallback: () => {
      return selectedEvent?.closed ? true : false;
    },
    hideDeleteCallback: () => {
      return selectedEvent?.closed ? true : false;
    },
    delete: {
      title: 'Ticket type verwijderen?',
      text: 'Weet je zeker dat je dit ticket type wilt verwijderen? Dit kan niet ongedaan worden gemaakt.',
    },
    sections: [
      {
        id: 'ticket_type_detail_section_0',
        title: 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'ticket_type_detail_section_0_field_0',
            label: 'Naam',
            name: 'name',
            required: true,
            value: selectedTicketType?.name,
            placeholder: '',
            type: 'text',
            width: 24,
            callback: (name: string, value: string) => {
              setSelectedTicketType((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Naam moet worden ingevuld',
                };
              }

              return { valid: true };
            },
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
          },
          {
            id: 'ticket_type_detail_section_0_field_1',
            label: 'Code',
            name: 'code',
            required: true,
            value: selectedTicketType?.code,
            placeholder: '',
            type: 'text',
            width: 24,
            callback: (name: string, value: string) => {
              setSelectedTicketType((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Code moet worden ingevuld',
                };
              }

              return { valid: true };
            },
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
          },
          {
            id: 'ticket_type_detail_section_0_field_3',
            label: 'Prijs',
            name: 'price',
            required: true,
            value: selectedTicketType?.price,
            placeholder: '',
            type: 'number',
            width: 24,
            callback: (name: string, value: string) => {
              setSelectedTicketType((prevState: any) => ({
                ...prevState,
                [name]: parseFloat(value),
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              // Convert the value to a number for easier manipulation and comparison

              // Check if the value is empty or zero
              if (!value && Number(value) !== 0) {
                return {
                  valid: false,
                  message: 'Prijs moet worden ingevuld',
                };
              }

              // Check if the value is negative
              if (Number(value) < 0) {
                return {
                  valid: false,
                  message: 'Prijs mag niet negatief zijn',
                };
              }

              // If no conditions are violated, the value is valid
              return { valid: true };
            },
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
          },
          {
            id: 'ticket_type_detail_section_0_field_EXTRA',
            label: 'Aantal deelnemers',
            name: 'numberAdditionalAttendees',
            required: true,
            value: selectedTicketType?.numberAdditionalAttendees ? selectedTicketType?.numberAdditionalAttendees : 1,
            placeholder: '',
            min: 1,
            type: 'number',
            width: 24,
            callback: (name: string, value: string) => {
              setSelectedTicketType((prevState: any) => ({
                ...prevState,
                [name]: parseFloat(value),
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Aantal moet worden ingevuld',
                };
              }

              return { valid: true };
            },
            disableCallback: () => {
              if (selectedEvent?.closed) {
                return true;
              }

              if (selectedTicketType?.id) {
                return true;
              }
              return false;
            },
          },
          {
            id: 'ticket_type_detail_section_0_field_2',
            label: 'Stock',
            name: 'stock',
            required: true,
            value: selectedTicketType?.stock,
            placeholder: '',
            type: 'number',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedTicketType((prevState: any) => ({
                ...prevState,
                [name]: parseFloat(value),
              }));
              return value;
            },
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Stock moet worden ingevuld',
                };
              }

              if (parseInt(value) < selectedTicketType!.amountSold) {
                return {
                  valid: false,
                  message: 'Stock kan niet lager zijn als het aantal al verkochte tickets',
                };
              }

              return { valid: true };
            },
          },
          {
            id: 'ticket_type_detail_section_0_field_2',
            label: 'Stock bericht min aantal',
            name: 'criticalStockLevel',
            value: selectedTicketType?.criticalStockLevel,
            placeholder: '',
            type: 'number',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedTicketType((prevState: any) => ({
                ...prevState,
                [name]: parseInt(parseFloat(value).toFixed(0)),
              }));
              return value;
            },
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
          },
          {
            id: 'ticket_type_detail_section_0_field_4',
            label: 'Beschikbaar vanaf',
            name: 'availableFrom',
            value: selectedTicketType?.availableFrom ? moment(selectedTicketType?.availableFrom).format('YYYY-MM-DD') : '',
            type: 'date',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedTicketType((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
          },
          {
            id: 'ticket_type_detail_section_0_field_5',
            label: 'Beschikbaar tot',
            name: 'availableTill',
            value: selectedTicketType?.availableTill ? moment(selectedTicketType?.availableTill).format('YYYY-MM-DD') : '',
            type: 'date',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedTicketType((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
          },
        ],
      },
    ],
  };

  // ===========================================
  // =============== EVENT STATE ===============

  const [selectedEvent, setSelectedEvent] = useState<IEvent | undefined>();
  const [selectedEventTab, setSelectedEventTab] = useState(DEFAULT_SELECTED_TAB);
  const [sortingEvents, setSortingEvents] = useState<ISorting>(DEFAULT_SORTING);
  const [openEventDetail, setOpenEventDetail] = useState(DEFAULT_DETAIL_OPEN);
  const [pagedState, setPagedState] = useState({
    skip: 0,
    take: 10,
  });

  const eventFilterSchema: InlineFilter = {
    id: 'event_filter_v1',
    fields: [
      {
        id: 'event_filter_field_1',
        label: 'Datum van',
        name: 'startTime',
        width: 20,
        value: moment().toISOString(),
        type: 'date',
        callback: (fieldValue: string) => ({
          gte: fieldValue ? moment(new Date(fieldValue)).toISOString() : undefined,
        }),
      },
      {
        id: 'event_filter_field_3',
        label: 'Datum tot',
        name: 'startTime',
        width: 20,
        type: 'date',
        callback: (fieldValue: string) => ({
          lte: fieldValue ? moment(new Date(fieldValue)).toISOString() : undefined,
        }),
      },
      {
        id: 'event_filter_field_8',
        label: 'Medewerkers',
        name: 'OR',
        value: selectedCustomer3
          ? ({
              id: selectedCustomer3?.id as any,
              name: `${selectedCustomer3?.firstName} ${selectedCustomer3?.lastName}`,
            } as IComboBoxOption)
          : undefined,
        width: 26,
        type: 'combobox',
        placeholder: 'Selecteer',
        combobox: {
          items: [
            { id: 'Alles', name: 'Alles', active: true },
            ...convertUsersToComboBoxOptions(
              dataCustomers3 && dataCustomers3.findManyUsers && !loadingCustomers3 && !errorCustomers3 ? dataCustomers3.findManyUsers : [],
            ),
          ],
          query: customerQueryString3,
          setQuery: setCustomerQueryString3,
        },
        callback: (fieldValue: any) => {
          if (fieldValue.id !== 'Alles') {
            console.log(fieldValue);
            // setSelectedCustomer2(dataCustomers2.findManyUsers.find((item: any) => item.id.toString() === fieldValue.id.toString()));
            return [
              {
                eventUsers: {
                  some: {
                    userID: Number(fieldValue.id),
                  },
                },
              },
            ];
          } else {
            setSelectedCustomer3({ id: '', name: '', active: true });
          }

          return [];
        },
      },
    ],
  };

  const [eventInlineFilterState, setEventInlineFilterState] = useState<InlineFilter>(initFilterPanelState(eventFilterSchema));

  const [openAttendeePDF, setOpenAttendeePDF] = useState(DEFAULT_DETAIL_OPEN);

  useEffect(() => {
    setPagedState({
      skip: 0,
      take: 10,
    });
  }, [searchQuery, eventInlineFilterState]);

  const eventColumns: IColumn[] = [
    {
      key: 'column0',
      name: 'Vorming',
      fieldName: 'event.location.name',
      render: (item: IEvent) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.activity?.title}</span>;
      },
    },
    {
      key: 'column1',
      name: 'Locatie',
      fieldName: 'event.location.name',
      render: (item: IEvent) => {
        return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.location?.name}</span>;
      },
    },
    {
      key: 'column2',
      name: 'Start',
      fieldName: 'event.startTime',
      render: (item: IEvent) => {
        return (
          <span className='text-ellipsis text-base overflow-hidden block w-30'>
            {item?.startTime && moment(item?.startTime).format(DEFAULT_DATE_FORMAT_LONG)}
          </span>
        );
      },
      sortable: true,
      isSorted: sortingEvents.key === 'startTime',
      isSortedDescending: sortingEvents.direction === 'desc',
      onHeaderClick: () => {
        setSortingEvents(prevState => {
          if (prevState.key === 'startTime') {
            return {
              key: 'startTime',
              direction: prevState.direction === 'asc' ? 'desc' : 'asc',
            };
          } else {
            return {
              key: 'startTime',
              direction: 'asc',
            };
          }
        });
      },
    },
    {
      key: 'column3',
      name: 'Eind',
      fieldName: 'event.endTime',
      render: (item: IEvent) => {
        return (
          <span className='text-ellipsis text-base overflow-hidden block w-30'>
            {item?.endTime && moment(item?.endTime).format(DEFAULT_DATE_FORMAT_LONG)}
          </span>
        );
      },
      sortable: true,
      isSorted: sortingEvents.key === 'endTime',
      isSortedDescending: sortingEvents.direction === 'desc',
      onHeaderClick: () => {
        setSortingEvents(prevState => {
          if (prevState.key === 'endTime') {
            return {
              key: 'endTime',
              direction: prevState.direction === 'asc' ? 'desc' : 'asc',
            };
          } else {
            return {
              key: 'endTime',
              direction: 'asc',
            };
          }
        });
      },
    },
    {
      key: 'column4',
      name: 'Status',
      fieldName: 'event.closed',
      render: (item: IEvent) => {
        let color = '#20A558';
        let string = `text-cego-white text-xs font-medium px-8 py-1.5 rounded-xs cursor-pointer text-center`;
        let text = item.closed ? 'Afgesloten' : 'Actief';

        if (item?.closed) {
          string = `text-cego-white text-xs font-medium px-4 py-1.5 rounded-xs cursor-pointer`;
          color = '#ff8e3b';
        }

        if (item?.closed && item?.status === 'cancelled') {
          color = '#FF4536';
          text = 'Geannuleerd';
          string = `text-cego-white text-xs font-medium px-2.5 py-1.5 rounded-xs cursor-pointer`;
        }

        return (
          <span className={'text-ellipsis overflow-hidden w-40 tracking-widest'}>
            <span className={string} style={{ backgroundColor: color }}>
              <span className={item?.closed ? 'text-center' : ''}>{text}</span>
            </span>
          </span>
        );
      },
    },
  ];

  const getFilters = useCallback(
    (filterString: string, inlineFilter?: InlineFilter | undefined) => {
      const filterArray = filterString.split(' ');

      const filterObject: any = {
        AND: [],
      };

      if (inlineFilter && inlineFilter.fields && inlineFilter.fields.length > 0) {
        for (let i = 0; i < inlineFilter.fields.length; i++) {
          const field = inlineFilter.fields[i];

          if ((field as any).parsedValue) {
            filterObject.AND.push((field as any).parsedValue);
          }
        }
      }

      for (let i = 0; i < filterArray.length; i++) {
        const filterValue: any = {
          OR: [
            {
              location: { contains: filterArray[i] },
            },
            {
              orders: {
                some: {
                  customer: {
                    institution: {
                      contains: filterArray[i],
                    },
                  },
                },
              },
            },
            {
              attendees: {
                some: {
                  firstName: {
                    contains: filterArray[i],
                  },
                },
              },
            },
            {
              attendees: {
                some: {
                  lastName: {
                    contains: filterArray[i],
                  },
                },
              },
            },
            {
              activity: {
                title: {
                  contains: filterArray[i],
                },
              },
            },
          ],
        };

        filterObject.AND.push(filterValue);
      }

      return filterObject;
    },
    [eventInlineFilterState],
  );

  const getOrderBy = useCallback(
    (sorting: any) => {
      return { [sorting.key]: sorting.direction === 'asc' ? 'asc' : 'desc' };
    },
    [sortingEvents],
  );

  const {
    data: dataEvents,
    loading: loadingEvents,
    error: errorEvents,
    refetch: refetchEvents,
    fetchMore: fetchMoreEvents,
  } = useQuery(GET_EVENTS, {
    variables: {
      filter: getFilters(searchQuery, eventInlineFilterState),
      orderBy: getOrderBy(sortingEvents),
      skip: pagedState.skip,
      take: pagedState.take,
    },
  });

  const [getEvent, { loading: loadingEvent, error: errorEvent, refetch: refetchEvent }] = useLazyQuery(GET_EVENT);
  const [updateEvent] = useMutation(UPDATE_EVENT);
  const [deleteEvent] = useMutation(DELETE_EVENT);
  const [closeEvent] = useMutation(CLOSE_EVENT);
  const [cancelEvent] = useMutation(CANCEL_EVENT);

  const handleSetEventSelection = (event?: IEvent) => {
    if (event?.id) {
      getEvent({
        variables: {
          where: {
            id: event?.id,
          },
        },
        onCompleted: data => {
          setSelectedEvent(data.findOneEvent);
        },
      });
    } else {
      setSelectedEvent({
        location: {
          name: '',
          zipcode: '',
        },
      });
    }

    setSelectedVenue(undefined);
    setSelectedEventUser(undefined);
    setOpenEventDetail(true);
  };

  const handleUpdateEvent = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedEvent?.id) {
            const updateEventObj: any = {
              location: {
                name: selectedEvent?.location?.name,
                address: selectedEvent?.location?.address,
                zipcode: selectedEvent?.location?.zipcode,
                city: selectedEvent?.location?.city,
              },
              extraDates: selectedEvent?.extraDates ? selectedEvent?.extraDates.map(({ __typename, ...cleanDate }) => cleanDate) : [],
              startTime: selectedEvent?.startTime ? new Date(selectedEvent?.startTime) : undefined,
              endTime: selectedEvent?.endTime ? new Date(selectedEvent?.endTime) : undefined,
              online: selectedEvent?.online,
            };

            updateEvent({
              variables: {
                id: selectedEvent?.id,
                data: updateEventObj,
              },
              onCompleted: data => {
                resolve(data.updateEvent);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const event: IEvent = (await res) as any;
      setSelectedEvent(event);
      refetchEvents({
        filter: getFilters(searchQuery, eventInlineFilterState),
        orderBy: getOrderBy(sortingEvents),
        skip: 0,
        take: pagedState.skip > 0 ? pagedState.skip : pagedState.take,
      }); // activitie

      toastSuccess('Evenement succesvol geupdate');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleCloseEvent = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedEvent?.id) {
            closeEvent({
              variables: {
                id: selectedEvent?.id,
              },
              onCompleted: data => {
                resolve(data.closeEvent);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const event: IEvent = (await res) as any;
      setSelectedEvent(event);
      refetchEvents({
        filter: getFilters(searchQuery, eventInlineFilterState),
        orderBy: getOrderBy(sortingEvents),
        skip: 0,
        take: pagedState.skip > 0 ? pagedState.skip : pagedState.take,
      }); // activitie
      setOpenEventDetail(false);

      toastSuccess('Evenement succesvol geupdate');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleCancelEvent = async (subject: string, body: string) => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedEvent?.id) {
            cancelEvent({
              variables: {
                data: {
                  eventID: selectedEvent?.id,
                  subject,
                  body,
                },
              },
              onCompleted: data => {
                resolve(data.cancelEvent);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      const event: IEvent = (await res) as any;
      setSelectedEvent(event);
      refetchEvents({
        filter: getFilters(searchQuery, eventInlineFilterState),
        orderBy: getOrderBy(sortingEvents),
        skip: 0,
        take: pagedState.skip > 0 ? pagedState.skip : pagedState.take,
      }); // activitie

      setIsOpenCancelOpenEventModal(false);

      toastSuccess('Evenement succesvol geupdate');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  // const handleCreateEvent = async () => {
  //   try {
  //     const res = await toast.promise(
  //       new Promise((resolve, reject) => {
  //         if (!selectedEvent?.id) {
  //           const createEventObj: any = {
  //             location: {
  //               name: selectedEvent?.location?.name,
  //               address: selectedEvent?.location?.address,
  //               zipcode: selectedEvent?.location?.zipcode,
  //               city: selectedEvent?.location?.city,
  //             },
  //             startTime: selectedEvent?.startTime ? new Date(selectedEvent?.startTime) : undefined,
  //             endTime: selectedEvent?.endTime ? new Date(selectedEvent?.endTime) : undefined,
  //             online: selectedEvent?.online,
  //             activity: {
  //               connect: {
  //                 id: selectedActivity?.id,
  //               },
  //             },
  //           };

  //           createEvent({
  //             variables: {
  //               data: createEventObj,
  //             },
  //             onCompleted: data => {
  //               resolve(data.createEvent);
  //             },
  //             onError: error => {
  //               reject(error);
  //             },
  //           });
  //         }
  //       }),
  //       {
  //         pending: {
  //           position: DEFAULT_TOAST_POSITION,
  //           render() {
  //             return DEFAULT_LOADING_MESSAGE;
  //           },
  //         },
  //         error: {
  //           position: DEFAULT_TOAST_POSITION,
  //           render() {
  //             return DEFAULT_ERROR_MESSAGE;
  //           },
  //           icon: FiAlertCircle,
  //         },
  //       },
  //       {
  //         autoClose: DEFAULT_TOAST_DURATION,
  //       },
  //     );

  //     const event: IEvent = (await res) as any;
  //     setSelectedEvent(event);
  //     refetchActivity(); // activitie

  //     toastSuccess('Evenement succesvol aangemaakt');
  //   } catch (error) {
  //     const message =
  //       error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
  //         ? (error as any).graphQLErrors[0].message
  //         : (error as any).message
  //         ? (error as any).message
  //         : DEFAULT_ERROR_MESSAGE;
  //     toastError(message);
  //   }
  // };

  const handleDeleteEvent = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          if (selectedEvent?.id) {
            deleteEvent({
              variables: {
                where: {
                  id: selectedEvent?.id,
                },
              },
              onCompleted: data => {
                resolve(undefined);
              },
              onError: error => {
                reject(error);
              },
            });
          }
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
          error: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_ERROR_MESSAGE;
            },
            icon: FiAlertCircle,
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      setSelectedEvent({
        location: {
          name: '',
          zipcode: '',
        },
      });
      refetchEvents({
        filter: getFilters(searchQuery, eventInlineFilterState),
        orderBy: getOrderBy(sortingEvents),
        skip: 0,
        take: pagedState.skip > 0 ? pagedState.skip : pagedState.take,
      }); // activitie
      setOpenEventDetail(false);

      toastSuccess('Event succesvol verwijderd');
    } catch (error) {
      const message =
        error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
          ? (error as any).graphQLErrors[0].message
          : (error as any).message
          ? (error as any).message
          : DEFAULT_ERROR_MESSAGE;
      toastError(message);
    }
  };

  const handleDownloadAttendeeExcel = async () => {
    const fileName = `attendees_${moment().format('YYYYMMDD')}.xlsx`;
    const token = await getAccessTokenSilently();
    const res = await fetch(`${process.env.REACT_APP_FILE_URI}/files/attendees/xls`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify({
        id: selectedEvent?.id,
      }),
    });

    const result = await res.blob();

    const aElement = document.createElement('a');
    aElement.setAttribute('download', fileName);
    const href = URL.createObjectURL(result);
    aElement.href = href;
    aElement.setAttribute('target', '_blank');
    aElement.click();
    URL.revokeObjectURL(href);
  };

  const [isOpenCloseEventModal, setIsOpenCloseOpenEventModal] = useState(false);
  const [isOpenCancelEventModal, setIsOpenCancelOpenEventModal] = useState(false);

  const eventDetailSchema: DetailSchema = {
    id: 'event_detail',
    object: selectedEvent,
    permission: '',
    selectedTab: selectedEventTab,
    setSelectedTab: setSelectedEventTab,
    handleUpdate: handleUpdateEvent,
    handleDelete: handleDeleteEvent,
    hideCreateCallback: () => {
      return true;
    },
    hideUpdateCallback: () => {
      return selectedEvent?.closed ? true : false;
    },
    hideDeleteCallback: () => {
      return selectedEvent?.closed ? true : false;
    },
    extraButtons: [
      {
        key: 'closeEvent',
        name: 'closeEvent',
        fieldName: 'action',
        render: () => {
          if (selectedEvent?.closed === true) {
          } else {
            return (
              <>
                <SmallModal open={isOpenCloseEventModal} setOpen={setIsOpenCloseOpenEventModal}>
                  <ConfirmationForm
                    title={'Evenement afsluiten'}
                    text={
                      'Weet je zeker dat je zeker dat je dit event wil afsluiten. Controleer de aanwezigheden, attesten worden meteen verstuurd.'
                    }
                    handleConfirm={() => {
                      handleCloseEvent();
                    }}
                    setIsOpenDelete={setIsOpenCloseOpenEventModal}
                  />
                </SmallModal>
                <Button
                  quaternary
                  onClick={() => {
                    setIsOpenCloseOpenEventModal(true);
                  }}
                >
                  {'Sluit event'}{' '}
                  <span className='inline ml-1'>
                    <LockClosedIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
                  </span>
                </Button>
              </>
            );
          }
        },
      },
      {
        key: 'cancelEvent',
        name: 'cancelEvent',
        fieldName: 'action',
        render: () => {
          if (selectedEvent?.closed === true) {
          } else {
            return (
              <>
                <Modal open={isOpenCancelEventModal} setOpen={setIsOpenCancelOpenEventModal}>
                  <ConfirmationAnulationForm
                    title={'Evenement annuleren'}
                    text={'Weet je zeker dat je zeker dat je dit event wil annuleren.'}
                    handleConfirm={handleCancelEvent}
                    setIsOpenDelete={setIsOpenCancelOpenEventModal}
                  />
                </Modal>
                <Button
                  quaternary
                  onClick={() => {
                    setIsOpenCancelOpenEventModal(true);
                  }}
                >
                  {'Annuleer event'}{' '}
                  <span className='inline ml-1'>
                    <LockClosedIcon className='h-3 w-3 text-cego-white' aria-hidden='true' />
                  </span>
                </Button>
              </>
            );
          }
        },
      },
    ],
    delete: {
      title: 'Evenement verwijderen?',
      text: 'Weet je zeker dat je dit evenement wilt verwijderen? Dit kan niet ongedaan worden gemaakt.',
    },
    sections: [
      {
        id: 'event_detail_section_0',
        title: 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'event_detail_section_1_field_158',
            label: 'Opgeslagen locaties',
            name: 'customer',
            required: true,
            value:
              selectedVenue !== undefined
                ? ({ id: selectedVenue?.id as any, name: selectedVenue?.name } as IComboBoxOption)
                : ({ id: 'selecteer', name: '' } as IComboBoxOption),
            placeholder: 'Selecteer',
            type: 'combobox',
            combobox: {
              items: convertVenuesToComboBoxOptions(
                dataVenues && dataVenues.findManyVenues && !loadingVenues && !errorVenues ? dataVenues.findManyVenues : [],
              ),
              query: venueQueryString,
              setQuery: setVenueQueryString,
            },
            width: 65,
            callback: (name: string, value: any) => {
              const completeVenue = dataVenues.findManyVenues.find((item: any) => item.id.toString() === value.id.toString());
              setSelectedVenue(completeVenue);
              setSelectedEvent((prevState: any) => ({
                ...prevState,
                ['location']: {
                  ['name']: completeVenue?.name,
                  ['address']: completeVenue?.address,
                  ['zipcode']: completeVenue?.zipcode,
                  ['city']: completeVenue?.city,
                },
              }));
              return value;
            },
            disableCallback: () => {
              if (selectedEvent?.closed === true) return true;
              if (selectedEvent?.online === true) return true;
              return false;
            },
            hideCallback: () => {
              return selectedEvent?.closed ? false : true;
            },
          },
          {
            id: 'event_detail_section_1_field_15842',
            label: 'Online',
            name: 'online',
            required: false,
            value: selectedEvent?.online,
            placeholder: '',
            type: 'switch',
            width: 20.5,
            callback: (name: string, value: any) => {
              setSelectedEvent((prevState: any) => ({
                ...prevState,
                online: value,
                ['location']: {
                  ...prevState.location,
                  ['name']: value === true ? 'Online' : prevState?.location?.name,
                  ['address']: value === true ? '' : prevState?.location?.address,
                  ['zipcode']: value === true ? '' : prevState?.location?.zipcode,
                  ['city']: value === true ? '' : prevState?.location?.city,
                },
              }));
              return value;
            },
            disableCallback: () => {
              return selectedEvent?.closed ? false : true;
            },
          },
          {
            id: 'event_detail_section_0_field_0',
            label: 'Naam',
            name: 'location.name',
            required: true,
            value: selectedEvent?.location.name,
            placeholder: '',
            type: 'text',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedEvent((prevState: any) => ({
                ...prevState,
                ['location']: {
                  ...prevState.location,
                  ['name']: value,
                },
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Naam moet worden ingevuld',
                };
              }

              return { valid: true };
            },
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
          },
          {
            id: 'event_detail_section_0_field_1',
            label: 'Adres',
            name: 'location.address',
            required: true,
            value: selectedEvent?.location.address,
            placeholder: '',
            type: 'text',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedEvent((prevState: any) => ({
                ...prevState,
                ['location']: {
                  ...prevState.location,
                  ['address']: value,
                },
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value && selectedEvent?.online !== true) {
                return {
                  valid: false,
                  message: 'Adres moet worden ingevuld',
                };
              }

              return { valid: true };
            },
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
            hideCallback: () => {
              return selectedEvent?.online ? false : true;
            },
          },
          {
            id: 'event_detail_section_0_field_2',
            label: 'Postcode',
            name: 'location.zipcode',
            required: true,
            value: selectedEvent?.location.zipcode,
            placeholder: '',
            type: 'text',
            width: 24,
            callback: (name: string, value: string) => {
              setSelectedEvent((prevState: any) => ({
                ...prevState,
                ['location']: {
                  ...prevState.location,
                  ['zipcode']: value,
                },
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value && selectedEvent?.online !== true) {
                return {
                  valid: false,
                  message: 'Postcode moet worden ingevuld',
                };
              }

              return { valid: true };
            },
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
            hideCallback: () => {
              return selectedEvent?.online ? false : true;
            },
          },
          {
            id: 'event_detail_section_0_field_3',
            label: 'Plaats',
            name: 'location.city',
            required: true,
            value: selectedEvent?.location.city,
            placeholder: '',
            type: 'text',
            width: 24,
            callback: (name: string, value: string) => {
              setSelectedEvent((prevState: any) => ({
                ...prevState,
                ['location']: {
                  ...prevState.location,
                  ['city']: value,
                },
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value && selectedEvent?.online !== true) {
                return {
                  valid: false,
                  message: 'Plaats moet worden ingevuld',
                };
              }

              return { valid: true };
            },
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
            hideCallback: () => {
              return selectedEvent?.online ? false : true;
            },
          },
          {
            id: 'event_detail_section_0_field_4',
            label: 'Start tijd',
            name: 'startTime',
            required: true,
            value: selectedEvent?.startTime ? moment(selectedEvent?.startTime).format('YYYY-MM-DDTHH:mm:ss') : '',
            type: 'datetime-local',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedEvent((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Start tijd moet worden ingevuld',
                };
              }

              return { valid: true };
            },
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
          },
          {
            id: 'event_detail_section_0_field_5',
            label: 'Eind tijd',
            name: 'endTime',
            required: true,
            value: selectedEvent?.endTime ? moment(selectedEvent?.endTime).format('YYYY-MM-DDTHH:mm:ss') : '',
            type: 'datetime-local',
            width: 48.5,
            callback: (name: string, value: string) => {
              setSelectedEvent((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            validateCallback: (value?: string): { valid: boolean; message?: string } => {
              if (!value) {
                return {
                  valid: false,
                  message: 'Eind tijd moet worden ingevuld',
                };
              }

              return { valid: true };
            },
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
          },
          {
            id: 'event_detail_section_0_field_7',
            label: 'Extra Datums',
            name: 'extraDates',
            //required: true,
            value: selectedEvent?.extraDates ? selectedEvent?.extraDates : [],
            type: 'extraDates',
            width: 100,
            callback: (name: string, value: any) => {
              setSelectedEvent((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
            disableCallback: () => {
              return selectedEvent?.closed ? true : false;
            },
            // validateCallback: (value?: any): { valid: boolean; message?: string } => {
            //   if (!value || value.length === 0 || (value.length === 1 && value[0] === '')) {
            //     return {
            //       valid: false,
            //       message: 'Goals moet worden ingevuld',
            //     };
            //   }

            //   return { valid: true };
            // },
          },
        ],
      },
      {
        id: 'event_detail_section_1',
        title: 'Tickets',
        width: 100,
        fields: [
          {
            id: 'event_detail_section_1_field_0',
            label: 'Ticket types',
            name: 'ticketTypes',
            value: selectedEvent?.ticketTypes,
            table: {
              onCreate: handleSetTicketTypeSelection,
              columns: ticketTypesColumns,
              items: selectedEvent?.ticketTypes ? selectedEvent.ticketTypes : [],
              onSelect: handleSetTicketTypeSelection,
              selection: selectedTicketType,
              showCreateCallback: () => {
                return selectedEvent?.closed ? false : true;
              },
            },
            type: 'table',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedEvent((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
          {
            id: 'event_detail_section_1_field_1',
            label: '',
            name: 'detail_ticket_types',
            value: selectedEvent?.ticketTypes,
            detail: {
              open: openTicketTypeDetail,
              setOpen: setOpenTicketTypeDetail,
              title: 'Ticket type',
              caption: selectedTicketType?.id ? 'Ticket type updaten' : 'Ticket type toevoegen',
              schema: ticketTypeDetailSchema,
            },
            type: 'detail',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedEvent((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
        ],
        hideCallback: () => {
          if (selectedEvent?.id) {
            return true;
          }
          return false;
        },
      },
      {
        id: 'event_detail_section_3',
        title: 'Sessies',
        width: 100,
        fields: [
          {
            id: 'event_detail_section_3_field_0',
            label: 'Sessie types',
            name: 'sessionTypes',
            value: selectedEvent?.sessionTypes,
            table: {
              onCreate: handleSetSessionTypeSelection,
              columns: sessionTypesColumns,
              items: selectedEvent?.sessionTypes ? selectedEvent.sessionTypes : [],
              onSelect: handleSetSessionTypeSelection,
              selection: selectedSessionType,
              showCreateCallback: () => {
                return selectedEvent?.closed ? false : true;
              },
            },
            type: 'table',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedEvent((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
          {
            id: 'event_detail_section_3_field_1',
            label: '',
            name: 'detail_session_types',
            value: selectedEvent?.sessionTypes,
            detail: {
              open: openSessionTypeDetail,
              setOpen: setOpenSessionTypeDetail,
              title: 'Session type',
              caption: selectedSessionType?.id ? 'Sessie type updaten' : 'Sessie type toevoegen',
              schema: sessionTypeDetailSchema,
            },
            type: 'detail',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedEvent((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
        ],
        hideCallback: () => {
          if (selectedEvent?.id) {
            return true;
          }
          return false;
        },
      },
      {
        id: 'event_detail_section_2',
        title: 'Kamers',
        width: 100,
        fields: [
          {
            id: 'event_detail_section_2_field_0',
            label: 'Kamer types',
            name: 'roomTypes',
            value: selectedEvent?.roomTypes,
            table: {
              onCreate: handleSetRoomTypeSelection,
              columns: roomTypesColumns,
              items: selectedEvent?.roomTypes ? selectedEvent.roomTypes : [],
              onSelect: handleSetRoomTypeSelection,
              selection: selectedRoomType,
              showCreateCallback: () => {
                return selectedEvent?.closed ? false : true;
              },
            },
            type: 'table',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedEvent((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
          {
            id: 'event_detail_section_2_field_1',
            label: '',
            name: 'detail_room_types',
            value: selectedEvent?.roomTypes,
            detail: {
              open: openRoomTypeDetail,
              setOpen: setOpenRoomTypeDetail,
              title: 'Kamer type',
              caption: selectedRoomType?.id ? 'Kamer type updaten' : 'Kamer type toevoegen',
              schema: roomTypeDetailSchema,
            },
            type: 'detail',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedEvent((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
        ],
        hideCallback: () => {
          if (selectedEvent?.id) {
            return true;
          }
          return false;
        },
      },
      {
        id: 'event_detail_section_2',
        title: `Deelnemers`,
        width: 20,
        fields: [
          {
            id: 'event_detail_section_8_field_0',
            label: `Deelnemers (${selectedEvent?.attendees?.length})`,
            name: 'attendees',
            value: selectedEvent?.attendees,
            table: {
              columns: selectedEvent?.closed ? closedAttendeeColumns : attendeeColumns,
              items: selectedEvent?.attendees ? selectedEvent.attendees : [],
              showCreateCallback: () => {
                return false;
              },
            },
            type: 'table',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedEvent((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
          {
            id: 'event_detail_section_8_field_1',
            label: 'Download Excel',
            name: 'attendees',
            value: selectedEvent?.attendees,
            custombutton: {
              text: 'Deelnemers',
              icon: <FaFileExcel className='h-3 w-3 text-cego-white' aria-hidden='true' />,
            },
            type: 'custom-button',
            width: 100,
            callback: (name: string, value: string) => {
              handleDownloadAttendeeExcel();
              return value;
            },
          },
          {
            id: 'event_detail_section_8_field_2',
            label: 'Download Pdf',
            name: 'attendees',
            value: selectedEvent?.attendees,
            custombutton: {
              text: 'Deelnemers',
              icon: <FaFilePdf className='h-3 w-3 text-cego-white' aria-hidden='true' />,
            },
            type: 'custom-button',
            width: 100,
            callback: (name: string, value: string) => {
              setOpenAttendeePDF(true);
              return value;
            },
          },
        ],
        hideCallback: () => {
          if (selectedEvent?.id) {
            return true;
          }
          return false;
        },
      },
      {
        id: 'event_detail_section_5',
        title: 'Medewerkers',
        width: 100,
        fields: [
          {
            id: 'event_detail_section_5_field_1',
            label: 'Evenement medewerkers',
            name: 'eventUsers',
            value: selectedEvent?.eventUsers,
            fasttable: {
              columns: eventUserColumns,
              items: selectedEvent?.eventUsers ? selectedEvent?.eventUsers : [],
            },
            type: 'fast-table',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedEvent((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
          {
            id: 'event_detail_section_5_field_2',
            label: 'Medewerker',
            name: 'id',
            required: true,
            value: selectedEventUser
              ? resolveValueForCombobox(
                  selectedEventUser?.id ? selectedEventUser?.id : '',
                  convertUsersToComboBoxOptions(
                    dataUsers && dataUsers.findManyUsers && !loadingUsers && !errorUsers ? dataUsers.findManyUsers : [],
                  ),
                )
              : ({ id: 'selecteer', name: '' } as IComboBoxOption),
            placeholder: 'Selecteer',
            type: 'combobox',
            combobox: {
              items: convertUsersToComboBoxOptions(
                dataUsers && dataUsers.findManyUsers && !loadingUsers && !errorUsers
                  ? selectedEvent?.id
                    ? dataUsers.findManyUsers
                    : filterComboBoxUserItem(dataUsers.findManyUsers)
                  : [],
              ),
            },
            width: 32.5,
            callback: (name: string, value: any) => {
              setSelectedEventUser((prevState: any) => ({
                ...prevState,
                [name]: value.id,
              }));
              return value;
            },
            hideCallback: () => {
              if (selectedEvent?.closed === true) {
                return false;
              }
              return true;
            },
          },
          {
            id: 'event_detail_section_5_field_3',
            label: '',
            name: '',
            value: '',
            placeholder: '',
            type: 'add-button',
            width: 66,
            callback: (name: string, value: any) => {
              handleCreateEventUser();
              return value;
            },
            disableCallback(value) {
              if (selectedEvent?.eventUsers && selectedEvent?.eventUsers.some(item => item.userID === selectedEventUser?.id)) {
                return true;
              }

              if (selectedEventUser?.id?.toString() === 'selecteer') {
                return true;
              }

              return selectedEventUser?.id ? false : true;
            },
            hideCallback: () => {
              if (selectedEvent?.closed === true) {
                return false;
              }
              return true;
            },
          },
        ],
        hideCallback: () => {
          if (selectedEvent?.id) {
            return true;
          }
          return false;
        },
      },
      {
        id: 'proposal_detail_section_2',
        title: 'Mailings',
        width: 100,
        fields: [
          {
            id: 'mailing_detail_section_1_field_3',
            label: 'Mailings',
            name: 'mailings',
            value: selectedEvent?.mailings,
            table: {
              onCreate: handleSetMailingSelection,
              columns: mailingColumns,
              items: selectedEvent?.mailings ? selectedEvent.mailings : [],
              onSelect: handleSetMailingSelection,
              selection: selectedMailing,
              showCreateCallback: () => {
                return selectedEvent?.closed ? false : true;
              },
            },
            type: 'table',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedEvent((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
          {
            id: 'activity_detail_section_1_field_4',
            label: '',
            name: 'detail',
            value: selectedEvent?.mailings,
            detail: {
              open: openMailingDetail,
              setOpen: setOpenMailingDetail,
              title: 'Mailing',
              caption: selectedMailing?.id ? 'Verzonde mailing' : 'deze mailing wordt verzonden naar elke deelnemer van dit event',
              schema: mailingDetailSchema,
            },
            type: 'detail',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedEvent((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
        ],
        hideCallback: () => {
          if (!selectedEvent?.id) {
            return false;
          }
          return true;
        },
      },
      {
        id: 'proposal_detail_section_x2x',
        title: 'Wachtlijst',
        width: 100,
        fields: [
          {
            id: 'waitinglist_detail_section_1_field_3',
            label: 'Deelnemers',
            name: 'waitinglist',
            value: selectedEvent?.waitingList,
            table: {
              onCreate: undefined,
              columns: waitingListColumns,
              items: selectedEvent?.waitingList ? selectedEvent.waitingList : [],
              onSelect: undefined,
              selection: undefined,
              showCreateCallback: () => {
                return false;
              },
            },
            type: 'table',
            width: 100,
            callback: (name: string, value: string) => {
              setSelectedEvent((prevState: any) => ({
                ...prevState,
                [name]: value,
              }));
              return value;
            },
          },
        ],
        hideCallback: () => {
          if (!selectedEvent?.id) {
            return false;
          }
          return true;
        },
      },
    ],
  };

  // ===========================================
  // ============= ACTIVITY STATE ==============

  // const [selectedActivity, setSelectedActivity] = useState<IActivity | undefined>();
  // const [selectedActivityTab, setSelectedActivityTab] = useState(DEFAULT_SELECTED_TAB);
  // const [sortingActivities, setSortingActivities] = useState<ISorting>(DEFAULT_SORTING);
  // const [openActivityDetail, setOpenActivityDetail] = useState(DEFAULT_DETAIL_OPEN);

  // const [selectedFile, setSelectedFile] = useState<File | null>(null);

  // const activityColumns: IColumn[] = [
  //   {
  //     key: 'column1',
  //     name: 'Titel',
  //     fieldName: 'activity.title',
  //     render: (item: IActivity) => {
  //       return <span className='text-ellipsis text-base overflow-hidden block w-30'>{item?.title}</span>;
  //     },
  //     sortable: true,
  //     isSorted: sortingActivities && sortingActivities.key === 'createdAt',
  //     isSortedDescending: sortingActivities && sortingActivities.direction === 'desc',
  //   },
  //   {
  //     key: 'column2',
  //     name: 'Type',
  //     fieldName: 'activity.entity.title',
  //     render: (item: IActivity) => {
  //       const color = resolveEntityColor(item?.entity?.title);
  //       const string = `text-cego-white uppercase text-xs font-medium px-2 py-1.5 rounded-xs`;

  //       return (
  //         <span className={'text-ellipsis overflow-hidden w-40 tracking-widest	t'}>
  //           <span className={string} style={{ backgroundColor: color }}>
  //             {item.entity.title}
  //           </span>
  //         </span>
  //       );
  //     },
  //     onHeaderClick: () => {
  //       setSortingActivities(prevState => {
  //         if (prevState.key === 'naam') {
  //           return {
  //             key: 'naam',
  //             direction: prevState.direction === 'asc' ? 'desc' : 'asc',
  //           };
  //         } else {
  //           return {
  //             key: 'naam',
  //             direction: 'asc',
  //           };
  //         }
  //       });
  //     },
  //   },
  //   {
  //     key: 'column3',
  //     name: 'Aantal events',
  //     fieldName: 'activity.activityType.title',
  //     render: (item: IActivity) => {
  //       return <span className='text-ellipsis overflow-hidden block w-40 text-base'>{item?.events?.length}</span>;
  //     },
  //   },
  // ];

  // const [getActivity, { loading: loadingActivity, error: errorActivity, refetch: refetchActivity }] = useLazyQuery(GET_ACTIVITY);
  // const [createActivity] = useMutation(CREATE_ACTIVITY);
  // const [updateActivity] = useMutation(UPDATE_ACTIVITY);
  // const [deleteActivity] = useMutation(DELETE_ACTIVITY);

  // const handleSetActivitySelection = (activity?: IActivity) => {
  //   if (activity?.id) {
  //     getActivity({
  //       variables: {
  //         where: {
  //           id: activity?.id,
  //         },
  //       },
  //       onCompleted: data => {
  //         setSelectedActivity(data.findOneActivity);
  //         setOpenActivityDetail(true);
  //       },
  //     });
  //   } else {
  //     setSelectedActivity({});
  //   }

  //   setOpenActivityDetail(true);
  // };

  // const handleUpdateActivity = async () => {
  //   try {
  //     const res = await toast.promise(
  //       new Promise(async (resolve, reject) => {
  //         if (selectedActivity?.id) {
  //           let url = '';

  //           if (selectedFile) {
  //             try {
  //               const data = new FormData();
  //               data.append('file', selectedFile as any);
  //               data.append('upload_preset', 'cego_dashboard_preset');
  //               data.append('cloud_name', 'dzu3bqpsr');

  //               const response = await fetch('https://api.cloudinary.com/v1_1/dzu3bqpsr/image/upload', {
  //                 method: 'post',
  //                 body: data,
  //               });

  //               if (!response.ok) {
  //                 reject('Upload ging mis, bestand heeft verkeerd formaat');
  //               }

  //               const dataResponse = await response.json();
  //               url = dataResponse.url;
  //             } catch {
  //               reject('Upload ging mis, bestand heeft verkeerd formaat');
  //             }
  //           }

  //           const updateActivityObj: any = {
  //             title: selectedActivity?.title,
  //             slug: selectedActivity?.slug ? selectedActivity?.slug : selectedActivity.title?.replace(' ', '-').toLowerCase(),
  //             activityType: {
  //               connect: {
  //                 id: selectedActivity?.activityTypeID,
  //               },
  //             },
  //             themeType: {
  //               connect: {
  //                 id: selectedActivity?.themeTypeID,
  //               },
  //             },
  //             targetAudienceType: {
  //               connect: {
  //                 id: selectedActivity?.targetAudienceTypeID,
  //               },
  //             },
  //             entity: {
  //               connect: {
  //                 id: selectedActivity?.entityID,
  //               },
  //             },
  //             body: selectedActivity?.body,
  //             usp: selectedActivity?.usp,
  //             goals: selectedActivity?.goals?.filter(n => n),
  //             additional: selectedActivity?.additional,
  //             featuredID: selectedActivity?.featuredID,
  //             featuredIDPrismic: selectedActivity?.featuredIDPrismic,
  //             image: selectedFile ? url : selectedActivity?.image,
  //           };

  //           updateActivity({
  //             variables: {
  //               id: selectedActivity?.id,
  //               data: updateActivityObj,
  //             },
  //             onCompleted: data => {
  //               resolve(data.updateActivity);
  //             },
  //             onError: error => {
  //               reject(error);
  //             },
  //           });
  //           setSelectedFile(null);
  //         }
  //       }),
  //       {
  //         pending: {
  //           position: DEFAULT_TOAST_POSITION,
  //           render() {
  //             return DEFAULT_LOADING_MESSAGE;
  //           },
  //         },
  //         error: {
  //           position: DEFAULT_TOAST_POSITION,
  //           render() {
  //             return DEFAULT_ERROR_MESSAGE;
  //           },
  //           icon: FiAlertCircle,
  //         },
  //       },
  //       {
  //         autoClose: DEFAULT_TOAST_DURATION,
  //       },
  //     );

  //     const newUpdatedActivity: IActivity = (await res) as any;

  //     setSelectedActivity(newUpdatedActivity);
  //     refetchActivities();

  //     toastSuccess('Vorming succesvol geupdate');
  //   } catch (error) {
  //     const message =
  //       error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
  //         ? (error as any).graphQLErrors[0].message
  //         : (error as any).message
  //         ? (error as any).message
  //         : DEFAULT_ERROR_MESSAGE;
  //     toastError(message as string);
  //   }
  // };

  // const handleCreateActivity = async () => {
  //   try {
  //     const res = await toast.promise(
  //       new Promise(async (resolve, reject) => {
  //         let url = '';

  //         if (selectedFile) {
  //           try {
  //             const data = new FormData();
  //             data.append('file', selectedFile as any);
  //             data.append('upload_preset', 'cego_dashboard_preset');
  //             data.append('cloud_name', 'dzu3bqpsr');

  //             const response = await fetch('https://api.cloudinary.com/v1_1/dzu3bqpsr/image/upload', {
  //               method: 'post',
  //               body: data,
  //             });

  //             if (!response.ok) {
  //               reject('Upload ging mis, bestand heeft verkeerd formaat');
  //             }

  //             const dataResponse = await response.json();
  //             url = dataResponse.url;
  //           } catch {
  //             reject('Upload ging mis, bestand heeft verkeerd formaat');
  //           }
  //         }

  //         if (!selectedActivity?.id) {
  //           const createActivityObj: any = {
  //             title: selectedActivity?.title,
  //             slug: selectedActivity?.slug ? selectedActivity?.slug : selectedActivity?.title?.replace(' ', '-').toLowerCase(),
  //             activityType: {
  //               connect: {
  //                 id: selectedActivity?.activityTypeID,
  //               },
  //             },
  //             themeType: {
  //               connect: {
  //                 id: selectedActivity?.themeTypeID,
  //               },
  //             },
  //             targetAudienceType: {
  //               connect: {
  //                 id: selectedActivity?.targetAudienceTypeID,
  //               },
  //             },
  //             entity: {
  //               connect: {
  //                 id: selectedActivity?.entityID,
  //               },
  //             },
  //             body: selectedActivity?.body,
  //             usp: selectedActivity?.usp,
  //             goals: selectedActivity?.goals?.filter(n => n),
  //             additional: selectedActivity?.additional,
  //             image: selectedFile ? url : selectedActivity?.image,
  //           };

  //           createActivity({
  //             variables: {
  //               data: createActivityObj,
  //             },
  //             onCompleted: data => {
  //               resolve(data.createActivity);
  //             },
  //             onError: error => {
  //               reject(error);
  //             },
  //           });
  //           setSelectedFile(null);
  //         }
  //       }),
  //       {
  //         pending: {
  //           position: DEFAULT_TOAST_POSITION,
  //           render() {
  //             return DEFAULT_LOADING_MESSAGE;
  //           },
  //         },
  //         error: {
  //           position: DEFAULT_TOAST_POSITION,
  //           render() {
  //             return DEFAULT_ERROR_MESSAGE;
  //           },
  //           icon: FiAlertCircle,
  //         },
  //       },
  //       {
  //         autoClose: DEFAULT_TOAST_DURATION,
  //       },
  //     );

  //     const newCreatedActivity: IActivity = (await res) as any;
  //     setSelectedActivity(newCreatedActivity);
  //     refetchActivities();

  //     toastSuccess('Vorming succesvol geupdate');
  //   } catch (error) {
  //     const message =
  //       error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
  //         ? (error as any).graphQLErrors[0].message
  //         : (error as any).message
  //         ? (error as any).message
  //         : DEFAULT_ERROR_MESSAGE;
  //     toastError(message as string);
  //   }
  // };

  // const handleDeleteActivity = async () => {
  //   try {
  //     const res = await toast.promise(
  //       new Promise((resolve, reject) => {
  //         if (selectedActivity?.id) {
  //           deleteActivity({
  //             variables: {
  //               where: {
  //                 id: selectedActivity?.id,
  //               },
  //             },
  //             onCompleted: data => {
  //               resolve(undefined);
  //             },
  //             onError: error => {
  //               reject(error);
  //             },
  //           });
  //         }
  //       }),
  //       {
  //         pending: {
  //           position: DEFAULT_TOAST_POSITION,
  //           render() {
  //             return DEFAULT_LOADING_MESSAGE;
  //           },
  //         },
  //         error: {
  //           position: DEFAULT_TOAST_POSITION,
  //           render() {
  //             return DEFAULT_ERROR_MESSAGE;
  //           },
  //           icon: FiAlertCircle,
  //         },
  //       },
  //       {
  //         autoClose: DEFAULT_TOAST_DURATION,
  //       },
  //     );

  //     setSelectedActivity(undefined);
  //     refetchActivities();
  //     setOpenActivityDetail(false);

  //     toastSuccess('Vorming succesvol verwijderd');
  //   } catch (error) {
  //     const message =
  //       error && (error as any).graphQLErrors && (error as any).graphQLErrors.length > 0 && (error as any).graphQLErrors[0].message
  //         ? (error as any).graphQLErrors[0].message
  //         : (error as any).message
  //         ? (error as any).message
  //         : DEFAULT_ERROR_MESSAGE;
  //     toastError(message);
  //   }
  // };

  // const activityDetailSchema: DetailSchema = {
  //   id: 'activity_detail',
  //   object: selectedActivity,
  //   permission: '',
  //   selectedTab: selectedActivityTab,
  //   setSelectedTab: setSelectedActivityTab,
  //   handleUpdate: handleUpdateActivity,
  //   handleCreate: handleCreateActivity,
  //   handleDelete: handleDeleteActivity,
  //   delete: {
  //     title: 'Vorming verwijderen?',
  //     text: 'Weet je zeker dat je deze vorming wilt verwijderen? Dit kan niet ongedaan worden gemaakt.',
  //   },
  //   sections: [
  //     {
  //       id: 'activity_detail_section_0',
  //       title: 'Algemeen',
  //       width: 100,
  //       fields: [
  //         {
  //           id: 'activity_detail_section_0_field_0',
  //           label: 'Titel',
  //           name: 'title',
  //           required: true,
  //           value: selectedActivity?.title,
  //           placeholder: '',
  //           type: 'text',
  //           width: 48.5,
  //           callback: (name: string, value: string) => {
  //             if (!selectedActivity?.id) {
  //               setSelectedActivity((prevState: any) => ({
  //                 ...prevState,
  //                 [name]: value,
  //                 ['slug']: value.toLocaleLowerCase().replace(/ /g, '-'),
  //               }));
  //             } else {
  //               setSelectedActivity((prevState: any) => ({
  //                 ...prevState,
  //                 [name]: value,
  //               }));
  //             }
  //             return value;
  //           },
  //           validateCallback: (value?: string): { valid: boolean; message?: string } => {
  //             if (!value) {
  //               return {
  //                 valid: false,
  //                 message: 'Titel moet worden ingevuld',
  //               };
  //             }

  //             return { valid: true };
  //           },
  //         },
  //         {
  //           id: 'activity_detail_section_0_field_1',
  //           label: 'Slug',
  //           name: 'slug',
  //           required: true,
  //           value: selectedActivity?.slug,
  //           placeholder: '',
  //           type: 'text',
  //           width: 48.5,
  //           callback: (name: string, value: string) => {
  //             setSelectedActivity((prevState: any) => ({
  //               ...prevState,
  //               [name]: value.toLocaleLowerCase().replace(/ /g, '-'),
  //             }));
  //             return value;
  //           },
  //           validateCallback: (value?: string): { valid: boolean; message?: string } => {
  //             if (!value) {
  //               return {
  //                 valid: false,
  //                 message: 'Slug moet worden ingevuld',
  //               };
  //             }

  //             return { valid: true };
  //           },
  //         },
  //         {
  //           id: 'activity_detail_section_0_field_2',
  //           label: 'USP',
  //           name: 'usp',
  //           required: true,
  //           value: selectedActivity?.usp,
  //           placeholder: '',
  //           type: 'text',
  //           width: 100,
  //           callback: (name: string, value: string) => {
  //             setSelectedActivity((prevState: any) => ({
  //               ...prevState,
  //               [name]: value,
  //             }));
  //             return value;
  //           },
  //           validateCallback: (value?: string): { valid: boolean; message?: string } => {
  //             if (!value) {
  //               return {
  //                 valid: false,
  //                 message: 'Usp moet worden ingevuld',
  //               };
  //             }

  //             return { valid: true };
  //           },
  //         },
  //         {
  //           id: 'activity_detail_section_0_field_3',
  //           label: 'Type',
  //           name: 'activityTypeID',
  //           required: true,
  //           value: resolveValueForCombobox(
  //             selectedActivity?.activityTypeID ? selectedActivity.activityTypeID : '',
  //             convertEntitiesToComboBoxOptions(
  //               dataActivityTypes && dataActivityTypes.findManyActivityTypes && !loadingActivityTypes && !errorActivityTypes
  //                 ? dataActivityTypes.findManyActivityTypes
  //                 : [],
  //             ),
  //           ),
  //           placeholder: '',
  //           type: 'combobox',
  //           combobox: {
  //             items: convertEntitiesToComboBoxOptions(
  //               dataActivityTypes && dataActivityTypes.findManyActivityTypes && !loadingActivityTypes && !errorActivityTypes
  //                 ? dataActivityTypes.findManyActivityTypes
  //                 : [],
  //             ),
  //           },
  //           width: 24.5,
  //           callback: (name: string, value: any) => {
  //             setSelectedActivity((prevState: any) => ({
  //               ...prevState,
  //               [name]: value.id,
  //             }));
  //             return value;
  //           },
  //           validateCallback: (value?: string): { valid: boolean; message?: string } => {
  //             if (!value) {
  //               return {
  //                 valid: false,
  //                 message: 'Type moet worden ingevuld',
  //               };
  //             }

  //             return { valid: true };
  //           },
  //         },
  //         {
  //           id: 'activity_detail_section_0_field_4',
  //           label: 'Entiteit',
  //           name: 'entityID',
  //           required: true,
  //           value: resolveValueForCombobox(
  //             selectedActivity?.entityID ? selectedActivity.entityID : '',
  //             convertEntitiesToComboBoxOptions(
  //               dataEntities && dataEntities.findManyEntities && !loadingEntities && !errorEntities ? dataEntities.findManyEntities : [],
  //             ),
  //           ),
  //           placeholder: '',
  //           type: 'combobox',
  //           combobox: {
  //             items: convertEntitiesToComboBoxOptions(
  //               dataEntities && dataEntities.findManyEntities && !loadingEntities && !errorEntities ? dataEntities.findManyEntities : [],
  //             ),
  //           },
  //           width: 24.5,
  //           callback: (name: string, value: any) => {
  //             setSelectedActivity((prevState: any) => ({
  //               ...prevState,
  //               [name]: value.id,
  //             }));
  //             return value;
  //           },
  //           validateCallback: (value?: string): { valid: boolean; message?: string } => {
  //             if (!value) {
  //               return {
  //                 valid: false,
  //                 message: 'Entiteit moet worden ingevuld',
  //               };
  //             }

  //             return { valid: true };
  //           },
  //         },
  //         {
  //           id: 'activity_detail_section_0_field_xx4',
  //           label: 'Theme',
  //           name: 'themeTypeID',
  //           required: true,
  //           value: resolveValueForCombobox(
  //             selectedActivity?.themeTypeID ? selectedActivity.themeTypeID : '',
  //             convertThemeTypesToComboBoxOptions(
  //               dataThemeTypes && dataThemeTypes.findManyThemeTypes && !loadingThemeTypes && !errorThemeTypes
  //                 ? dataThemeTypes.findManyThemeTypes
  //                 : [],
  //             ),
  //           ),
  //           placeholder: '',
  //           type: 'combobox',
  //           combobox: {
  //             items: convertThemeTypesToComboBoxOptions(
  //               dataThemeTypes && dataThemeTypes.findManyThemeTypes && !loadingThemeTypes && !errorThemeTypes
  //                 ? dataThemeTypes.findManyThemeTypes
  //                 : [],
  //             ),
  //           },
  //           width: 24.5,
  //           callback: (name: string, value: any) => {
  //             setSelectedActivity((prevState: any) => ({
  //               ...prevState,
  //               [name]: value.id,
  //             }));
  //             return value;
  //           },
  //           validateCallback: (value?: string): { valid: boolean; message?: string } => {
  //             if (!value) {
  //               return {
  //                 valid: false,
  //                 message: 'Theme moet worden ingevuld',
  //               };
  //             }

  //             return { valid: true };
  //           },
  //         },
  //         {
  //           id: 'activity_detail_section_0_field_xxx4',
  //           label: 'Doelgroep',
  //           name: 'targetAudienceTypeID',
  //           required: true,
  //           value: resolveValueForCombobox(
  //             selectedActivity?.targetAudienceTypeID ? selectedActivity.targetAudienceTypeID : '',
  //             convertTargetAudienceTypesToComboBoxOptions(
  //               dataTargetAudienceTypes &&
  //                 dataTargetAudienceTypes.findManyTargetAudienceTypes &&
  //                 !loadingTargetAudienceTypes &&
  //                 !errorTargetAudienceTypes
  //                 ? dataTargetAudienceTypes.findManyTargetAudienceTypes
  //                 : [],
  //             ),
  //           ),
  //           placeholder: '',
  //           type: 'combobox',
  //           combobox: {
  //             items: convertTargetAudienceTypesToComboBoxOptions(
  //               dataTargetAudienceTypes &&
  //                 dataTargetAudienceTypes.findManyTargetAudienceTypes &&
  //                 !loadingTargetAudienceTypes &&
  //                 !errorTargetAudienceTypes
  //                 ? dataTargetAudienceTypes.findManyTargetAudienceTypes
  //                 : [],
  //             ),
  //           },
  //           width: 24.5,
  //           callback: (name: string, value: any) => {
  //             setSelectedActivity((prevState: any) => ({
  //               ...prevState,
  //               [name]: value.id,
  //             }));
  //             return value;
  //           },
  //           validateCallback: (value?: string): { valid: boolean; message?: string } => {
  //             if (!value) {
  //               return {
  //                 valid: false,
  //                 message: 'Doelgroep moet worden ingevuld',
  //               };
  //             }

  //             return { valid: true };
  //           },
  //         },
  //         {
  //           id: 'activity_detail_section_0_field_5',
  //           label: 'Goals',
  //           name: 'goals',
  //           //required: true,
  //           value: selectedActivity?.goals && selectedActivity.goals.length > 0 ? selectedActivity.goals : [''],
  //           type: 'stringList',
  //           width: 100,
  //           callback: (name: string, value: any) => {
  //             setSelectedActivity((prevState: any) => ({
  //               ...prevState,
  //               [name]: value,
  //             }));
  //             return value;
  //           },
  //           // validateCallback: (value?: any): { valid: boolean; message?: string } => {
  //           //   if (!value || value.length === 0 || (value.length === 1 && value[0] === '')) {
  //           //     return {
  //           //       valid: false,
  //           //       message: 'Goals moet worden ingevuld',
  //           //     };
  //           //   }

  //           //   return { valid: true };
  //           // },
  //         },
  //         {
  //           id: 'activity_detail_section_0_field_6',
  //           label: 'Foto',
  //           name: 'image',
  //           value: selectedActivity?.image,
  //           //  required: true,
  //           type: 'dropzone',
  //           dropzone: {
  //             selectedFile: selectedFile,
  //             setSelectedFile: setSelectedFile,
  //           },
  //           width: 100,
  //           callback: (name: string, value: any) => {
  //             setSelectedActivity((prevState: any) => ({
  //               ...prevState,
  //               [name]: value,
  //             }));
  //             return value;
  //           },
  //           // validateCallback: (value?: any): { valid: boolean; message?: string } => {
  //           //   if (!selectedFile && !value) {
  //           //     return {
  //           //       valid: false,
  //           //       message: 'Upload een foto',
  //           //     };
  //           //   }

  //           //   return { valid: true };
  //           // },
  //         },
  //       ],
  //     },
  //     {
  //       id: 'activity_detail_section_1',
  //       title: 'Editor',
  //       width: 100,
  //       fields: [
  //         {
  //           id: 'activity_detail_section_1_field_0',
  //           label: 'Body',
  //           name: 'body',
  //           required: true,
  //           value: selectedActivity?.body,
  //           placeholder: '',
  //           type: 'editor',
  //           width: 100,
  //           callback: (name: string, value: string) => {
  //             setSelectedActivity((prevState: any) => ({
  //               ...prevState,
  //               [name]: value,
  //             }));
  //             return value;
  //           },
  //           validateCallback: (value?: string): { valid: boolean; message?: string } => {
  //             if (!value) {
  //               return {
  //                 valid: false,
  //                 message: 'Body moet worden ingevuld',
  //               };
  //             }

  //             return { valid: true };
  //           },
  //         },
  //       ],
  //     },
  //     {
  //       id: 'activity_detail_section_2',
  //       title: 'Evenementen',
  //       width: 100,
  //       fields: [
  //         {
  //           id: 'activity_detail_section_2_field_0',
  //           label: 'Evenementen',
  //           name: 'events',
  //           value: selectedActivity?.events,
  //           table: {
  //             onCreate: handleSetEventSelection,
  //             columns: eventColumns,
  //             items: selectedActivity?.events ? selectedActivity.events : [],
  //             onSelect: handleSetEventSelection,
  //             selection: selectedEvent,
  //             showCreate: true,
  //           },
  //           type: 'table',
  //           width: 100,
  //           callback: (name: string, value: string) => {
  //             setSelectedActivity((prevState: any) => ({
  //               ...prevState,
  //               [name]: value,
  //             }));
  //             return value;
  //           },
  //         },
  //         {
  //           id: 'activity_detail_section_2_field_1',
  //           label: '',
  //           name: 'detail',
  //           value: selectedActivity?.events,
  //           detail: {
  //             open: openEventDetail,
  //             setOpen: setOpenEventDetail,
  //             title: 'Evenement',
  //             caption: selectedEvent?.id ? 'Evenement updaten' : 'Evenement toevoegen',
  //             schema: eventDetailSchema,
  //           },
  //           type: 'detail',
  //           width: 100,
  //           callback: (name: string, value: string) => {
  //             setSelectedActivity((prevState: any) => ({
  //               ...prevState,
  //               [name]: value,
  //             }));
  //             return value;
  //           },
  //         },
  //       ],
  //       hideCallback: () => {
  //         if (selectedActivity?.id) {
  //           return true;
  //         }
  //         return false;
  //       },
  //     },
  //   ],
  // };

  // ===========================================

  return (
    <div id='activities'>
      <header>
        <h1 className='font-bold text-2xl'>Evenementen</h1>
      </header>
      <div className='my-4'>
        <div className='mb-4'>
          <FilterForm schema={eventFilterSchema} filterState={eventInlineFilterState} setFilterState={setEventInlineFilterState} />
        </div>
      </div>
      {errorEvents ? (
        <div className='flex min-h-full flex-col bg-white pt-40 pb-12'>
          <main className='mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8'>
            <div className='py-16'>
              <div className='text-center'>
                <p className='text-6xl font-bold text-cego-black'>Error</p>
                <h1 className='mt-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-base'>Er ging iets mis</h1>
                <p className='mt-2 text-base text-gray-500'>{'refresh de pagina en probeer opnieuw aan te melden'}</p>
              </div>
            </div>
          </main>
        </div>
      ) : loadingEvents ? (
        <div>
          <div>
            <div className={classNames('sweet-loading my-10 text-center block')}>
              <BeatLoader color={'#A9C1C9'} loading={true} size={15} aria-label='Loading Spinner' data-testid='loader' />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div>
            <Table
              items={dataEvents ? dataEvents.findManyEvents : []}
              columns={eventColumns}
              loading={loadingEvents}
              onSelect={handleSetEventSelection}
              selection={selectedEvent}
              lazyLoading={true}
              loadMore={true}
              loadMoreCallback={() => {
                const skip = pagedState.skip + pagedState.take;

                fetchMoreEvents({
                  variables: {
                    filter: getFilters(searchQuery, eventInlineFilterState),
                    orderBy: getOrderBy(sortingEvents),
                    skip: skip,
                    take: pagedState.take,
                  },
                });

                setPagedState(prevState => ({
                  ...prevState,
                  skip: skip,
                }));
              }}
            />
          </div>

          <Modal open={openEventDetail} setOpen={setOpenEventDetail}>
            {openEventDetail === true ? (
              <>
                {selectedEvent && (
                  <DetailForm
                    schema={eventDetailSchema}
                    title={'Evenement'}
                    caption={selectedEvent?.id ? 'Evenement updaten' : 'Evenement toevoegen'}
                  />
                )}
                {loadingEvent && !selectedEvent && (
                  <div className='h-5/6 flex items-center'>
                    <div className={classNames('sweet-loading text-center w-full')}>
                      <BeatLoader color={'#A9C1C9'} loading={true} size={15} aria-label='Loading Spinner' data-testid='loader' />
                    </div>
                  </div>
                )}
                {errorEvent && <p>Error</p>}
              </>
            ) : (
              <div />
            )}
          </Modal>

          <FullScreenModal open={openAttendeePDF} setOpen={setOpenAttendeePDF}>
            <>
              {openAttendeePDF && selectedEvent && selectedEvent.id && (
                <div className='h-full px-9 pt-16 pb-9'>
                  <PDFViewer height={'94%'} width={'100%'}>
                    <AttendeeDownloadTemplate event={selectedEvent} />
                  </PDFViewer>
                  <div className='pt-4'>
                    {' '}
                    {/* <Button
                      quaternary
                      onClick={() => {
                        setOpenAttendeePDF(false);
                      }}
                    >
                      {'Definitief versturen'}{' '}
                      <span className='inline ml-1'>
                        <Clip className='h-3 w-3 text-cego-white' aria-hidden='true' />
                      </span>
                    </Button> */}
                  </div>
                </div>
              )}
            </>
          </FullScreenModal>
        </div>
      )}
    </div>
  );
};

// =======================  PDF PROPOSAL GENERATOR ==========================

Font.register({
  family: 'Poppins',
  fonts: [{ src: fontPoppins }, { src: fontPoppinsSemiBold, fontWeight: 500 }, { src: fontPoppinsBold, fontWeight: 700 }],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    paddingBottom: 50,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 40,
    fontFamily: 'Poppins',
  },
  pageFooter: {
    position: 'absolute',
    fontSize: 9,
    bottom: 30,
    left: 0,
    right: 40,
    textAlign: 'right',
    // color: 'grey',
  },
  pageHeader: {
    fontSize: 28,
  },
  footerNote: {
    fontSize: 9,
  },
});

const AttendeeDownloadTemplate = ({ event }: { event: IEvent }) => (
  <Document>
    <Page size='A4' style={styles.page} wrap>
      {/* Header repeated on every page */}
      <View style={styles.pageHeader} fixed>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 25,
          }}
        >
          <View>
            <Image
              src={CEGO_LOGO}
              style={{
                width: 150,
              }}
            />
          </View>

          <View
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <View>
              <Text
                style={{
                  paddingBottom: 0,
                  marginBottom: 0,
                }}
              >
                {'DEELNEMERSLIJST'}
              </Text>
              <View>
                <Text
                  style={{
                    paddingBottom: 0,
                    marginBottom: 0,
                    fontSize: 10,
                  }}
                >
                  {event.activity?.title}
                </Text>
              </View>
            </View>
            <View>
              <Text
                style={{
                  paddingBottom: 0,
                  marginBottom: 0,
                  fontSize: 10,
                }}
              >
                {event.location.name} {event.startTime && moment(event.startTime).format('DD/MM/YYYY')}
              </Text>
              <Text
                style={{
                  paddingBottom: 0,
                  marginBottom: 0,
                  fontSize: 10,
                }}
              >
                ({event?.attendees?.length} deelnemers)
              </Text>
            </View>
          </View>
        </View>
      </View>

      {/* Table */}
      <View>
        {/* row */}
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
            borderRight: '1px solid #DAE5E8',
            borderLeft: '1px solid #DAE5E8',
            borderTop: '1px solid #DAE5E8',
            borderBottom: '1px solid #DAE5E8',
            padding: 10,
            fontSize: 12,
            backgroundColor: '#DAE5E8',
          }}
        >
          <View
            style={{
              width: '33%',
            }}
          >
            <Text>Achternaam</Text>
          </View>
          <View
            style={{
              width: '33%',
            }}
          >
            <Text>Voornaam</Text>
          </View>
          <View
            style={{
              width: '33%',
            }}
          >
            <Text>Aanwezig</Text>
          </View>
        </View>
      </View>
      {event.attendees?.map((attendee: IAttendee) => (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            fontSize: 10,
            padding: 10,
            borderRight: '1px solid #DAE5E8',
            borderLeft: '1px solid #DAE5E8',
            borderTop: '1px solid #DAE5E8',
          }}
          key={attendee.id}
        >
          <View
            style={{
              width: '33%',
            }}
          >
            <Text>{`${attendee.lastName}`}</Text>
          </View>

          <View
            style={{
              width: '33%',
            }}
          >
            <Text>{`${attendee.firstName}`}</Text>
          </View>

          <View
            style={{
              width: '33%',
            }}
          >
            <View
              style={{
                padding: '5px',
                height: '15px',
                width: '15px',
                borderRadius: '5px',
                border: '1px solid #DAE5E8',
              }}
            ></View>
          </View>
        </View>
      ))}
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          fontSize: 10,
          padding: 10,
          borderBottomRightRadius: 10,
          borderBottomLeftRadius: 10,
          borderRight: '1px solid #DAE5E8',
          borderLeft: '1px solid #DAE5E8',
          borderTop: '1px solid #DAE5E8',
          borderBottom: '1px solid #DAE5E8',
        }}
      >
        <View
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text>
            {event.activity?.title} {event.location.name} {event.startTime && moment(event.startTime).format('DD/MM/YYYY')}
          </Text>
        </View>
      </View>
      {/* Body */}

      {/* Footer repeated on every page */}
      <View style={styles.pageFooter} fixed>
        <Text render={({ pageNumber, totalPages }) => `Pagina ${pageNumber} van ${totalPages}`} />
      </View>
    </Page>
  </Document>
);

export default Events;

//30
//27
//150
