import { gql } from '@apollo/client';
import { IEvent } from './event';

export interface IOrder {
  id?: number;

  createdAt?: Date;
  createdByID?: number;
  createdBy?: any;
  updatedAt?: Date;
  updatedByID?: number;
  updatedBy?: any;

  customer?: any;
  customerID?: number;
  event?: IEvent;
  eventID?: number;

  status?: string;
  comments?: string;

  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;

  allowMarketing?: boolean;
  allowPhotos?: boolean;

  poNo?: string;

  totalPrice?: number;

  tickets?: any[];
  sessions?: any[];
  invoices?: any[];
  rooms: any[];
  projects: any[];
}

export interface ICreateOrder {
  id?: number;

  createdAt?: Date;
  createdByID?: number;
  createdBy?: any;
  updatedAt?: Date;
  updatedByID?: number;
  updatedBy?: any;

  customer?: any;
  customerID?: number;
  event?: Event;
  eventID?: number;
}

//limited obj for overvieuw table
export const GET_ORDERS = gql`
  query getOrders($filter: OrderFilterInput, $orderBy: OrderOrderByInput, $skip: Int, $take: Int) {
    findManyOrders(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
      createdAt
      updatedAt
      id
      poNo
      customerID
      customer {
        id
        institution
      }
      eventID
      event {
        id
        location {
          name
          city
        }
        activity {
          title
        }
      }
      tickets {
        id
        ticketTypeID
        ticketType {
          name
          price
          numberAdditionalAttendees
          hasAdditionalAttendees
        }
        price
        attendeeID
        attendee {
          id
          firstName
          lastName
          email
          phone
          additionalInformation
        }
      }
    }
  }
`;

// full object for detail page
export const GET_ORDER = gql`
  query getOrder($where: OrderWhereInput) {
    findOneOrder(where: $where) {
      id
      createdAt
      updatedAt
      customerID
      poNo
      embeddedCustomer
      embeddedInvoiceCustomer
      customer {
        id
        institution
        institutionID
        billingID
        address {
          address
          city
          zipcode
          country
        }
        billingCustomerID
        billingCustomer {
          id
          institution
          address {
            address
            city
            zipcode
            country
          }
          billingID
          phone
          email
        }
        phone
        email
      }
      totalPrice
      allowMarketing
      allowPhotos
      eventID
      event {
        id
        location {
          name
          city
        }
        startTime
        endTime
        sessionTypes {
          id
        }
        roomTypes {
          id
        }
      }
      firstName
      lastName
      phone
      email
      comments
      status
      tickets {
        id
        status
        ticketTypeID
        ticketType {
          name
          price
          numberAdditionalAttendees
          hasAdditionalAttendees
        }
        price
        attendeeID
        attendee {
          id
          firstName
          lastName
          email
          phone
          additionalInformation
          attendees {
            id
            firstName
            lastName
            email
            phone
          }
        }
      }
      rooms {
        id
        price
        status
        roomType {
          id
          name
        }
      }

      invoices {
        id
        eenvouwdigFacturerenInvoice {
          invoice_id
          client_id
          client_name
          total_without_tax
          total_with_tax
          reference
          type
          creditnotes {
            invoice_id
            information
            total_with_tax
            total_without_tax
          }
        }
      }
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation createOrder($data: OrderCreateInput!) {
    createOrder(data: $data) {
      id
      createdAt
      updatedAt
      customerID
      poNo
      customer {
        id
        firstName
        lastName
      }
      eventID
      event {
        id
        location {
          name
        }
      }
      tickets {
        id
        status
        ticketTypeID
        ticketType {
          name
          price
          numberAdditionalAttendees
          hasAdditionalAttendees
        }
        price
        attendeeID
        attendee {
          id
          firstName
          lastName
          email
          phone
          additionalInformation
        }
      }
      rooms {
        id
        price
        status
        roomType {
          id
          name
        }
      }
    }
  }
`;

export const CREATE_ORDER_WEBSITE = gql`
  mutation createOrderWebsite($data: OrderWebsiteCreateInput, $disableInvoice: Boolean) {
    createOrderWebsite(data: $data, disableInvoice: $disableInvoice) {
      id
      poNo
      customer {
        auth0ID
        institution
        institutionID
      }
      event {
        id
        attendees {
          firstName
          lastName
          email
          tickets {
            price
            ticketType {
              name
              price
              stock
              numberAdditionalAttendees
              hasAdditionalAttendees
            }
          }
        }
      }
      rooms {
        price
        roomType {
          name
          price
          stock
        }
      }
    }
  }
`;

export const UPDATE_ORDER = gql`
  mutation updateOrder($id: Int!, $data: OrderUpdateInput) {
    updateOrder(id: $id, data: $data) {
      id
      poNo
      createdAt
      updatedAt
      customerID
      customer {
        id
        firstName
        lastName
      }
      eventID
      event {
        id
        location {
          name
        }
      }
      tickets {
        id
        status
        ticketTypeID
        ticketType {
          name
          price
          numberAdditionalAttendees
          hasAdditionalAttendees
        }
        price
        attendeeID
        attendee {
          id
          firstName
          lastName
          email
          phone
          additionalInformation
        }
      }
      rooms {
        id
        price
        status
        roomType {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_ORDER = gql`
  mutation deleteOrder($where: ActivityWhereInput) {
    deleteOrder(where: $where) {
      id
    }
  }
`;
